import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Carousel from "nuka-carousel";
import { Box, Button } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import { updateProperty } from "./../../features/store/storeSlice";
import "./../../assets/scss/AvailableTime.scss";

export default function DurationOfPackage({ durations, handleWidgetIntervalCapacity }) {
  const { t } = useTranslation();
  const { reservation, theme } = useSelector((state) => state.store);
  const [selectedCarouselIndex, setSelectedCarouselIndex] = useState([0]);
  const [slidesToShow, setSlidesToShow] = useState(5);
  const { innerWidth: width, innerHeight: height } = window;

  const defaultBoxColor = {
    backgroundColor: theme.calendarBoxColor ? theme.calendarBoxColor : '',
    opacity: theme.defaultBoxOpacity ? theme.defaultBoxOpacity : '',
    color: theme.calendarLinkColor ? theme.calendarLinkColor : '',
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : '',
  };

  const selectedStyle = {
    color: theme.calendarSelectedColor ? theme.calendarSelectedColor : '',
    backgroundColor: theme.calendarSelectedAreaColor ? theme.calendarSelectedAreaColor : '',
    opacity: theme.selectedColorOpacity ? theme.selectedColorOpacity : '',
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ''
  };

  const style = { ...defaultBoxColor, ...selectedStyle };

  const dispatch = useDispatch();

  const chooseDuration = (duration) => {
    dispatch(updateProperty({ key: "duration", value: duration }));
    handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id, reservation.tableFor, 0, 0, duration);
  }

  useEffect(() => {
    if (width < 600 && width > 500) {
      setSlidesToShow(4);
    } else if (width <= 500) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(5);
    }
  }, [width]);

  const displayDuration = (duration) => {
    let durationHours = Math.floor(duration / 60);
    let durationMins = duration % 60;
    let result=durationHours?durationHours+'hr':'';
    if(durationMins) result=result+' '+durationMins+'min';
    return result;
  }

  return (
    <Box component={'div'} id="avaialableTime" className="dateSlider">
      <LayoutContainer
        spacing={1}
        alignItems="center"
        justifyContent="center"
        className="sectionTable"
      >
        <LayoutItem xs={12}>
                  <Carousel
            slideIndex={selectedCarouselIndex}
            slidesToScroll={2}
            className={durations && durations.length<=slidesToShow?'durationsLessThanFive':''}
            withoutControls={false}
            slidesToShow={slidesToShow}
            renderBottomCenterControls={() => {
              return false;
            }}
            cellSpacing={0}
            renderCenterLeftControls={({ previousSlide }) => (
              <ChevronLeftIcon
                className={'carouselRenderButtons'}
                onClick={previousSlide}
              />
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ChevronRightIcon
                className={'carouselRenderButtons'}
                onClick={nextSlide}
              />
            )}
          >
            {durations.map((value, index) => {
              return (
                <Box component={'div'} key={`duration-${index}`} className="available-time">
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => chooseDuration(value)}
                    fullWidth
                    style={{ ...(reservation.duration === value) ? style : defaultBoxColor }}
                    className={`iWannaHaveButton widgetDefaultButton ${reservation.duration === value ? 'selected' : ''}`}
                  >
                    {displayDuration(value)}
                  </Button>
                </Box>
              );
            })}
          </Carousel>
        </LayoutItem>
      </LayoutContainer>
    </Box>
  );
}
