import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Button, Divider, Typography } from "@material-ui/core";
import { resetReservation, updateStateVariable } from "./../../features/store/storeSlice";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import Header from "./Header";
import ReservationDetail from "../shared/ReservationDetail";
import Packages from "../event/Packages";
import './../../assets/scss/Success.scss';
import Copyright from './Copyright';
import TagManager from 'react-gtm-module';
import { getReservationById } from '../../features/store/api';
import ReactPixel from "react-facebook-pixel";

export default function PaymentSuccess(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { loaderToggle, reservation, theme, venueDetails,defaultLang,settingsLoaded } = useSelector((state) => state.store);
  const reservationInfo = JSON.parse(localStorage.getItem('reservation_info'));
  const [paidAmount, setPaidAmount] = useState(reservationInfo.total);
  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if(settingsLoaded){
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    let ruuid = query.get("ruuid");
    let partialAmount = query.get("partialAmount");
    getReservationById(ruuid,oid).then((response=>{

    let gtmEvent;
    console.log('theme.googleTagsManager: ',theme.googleTagsManager,theme.googleTagsManagerId)
    if (theme.googleTagsManager && theme.googleTagsManagerId) {
      console.log('innn')
      gtmEvent = {
        'event': 'servme_reservation_added',
        'reservation_data': reservationInfo.tableFor + ' people, ' + moment(reservationInfo.date).format('ddd DD MMM YYYY') + ' ' + t("at") + ' ' + timeFormater(reservationInfo.time) + '. Notes: ' + reservationInfo.reservation_note,
        // 'reservation_booking_datetime': moment().format('YYYY-MM-DD HH:mm'),
        // 'reservation_datetime': moment(obj.reservation_datetime).format('YYYY-MM-DD HH:mm'),
        // 'reservation_guest_count': obj.guest_count,
        // 'reservation_timeslot': moment(obj.reservation_datetime).format('HH:mm'),
        'reservation_country': reservationInfo.country_name,
        'language':defaultLang
        // 'reservation_outlet': venueDetails.outlet_name
      }
      if(queryParams.get('utm_source')){
        gtmEvent.utm_source = queryParams.get('utm_source');
      }
      if(queryParams.get('utm_medium')){
        gtmEvent.utm_medium = queryParams.get('utm_medium');
      }
      if(queryParams.get('utm_campaign')){
        gtmEvent.utm_campaign = queryParams.get('utm_campaign');
      }
      if(queryParams.get('utm_content')){
        gtmEvent.utm_content = queryParams.get('utm_content');
      }
      if(queryParams.get('Creative')){
        gtmEvent.Creative = queryParams.get('Creative');
      }
      if(queryParams.get('Adset')){
        gtmEvent.Adset = queryParams.get('Adset');
      }
      if(queryParams.get('_gl')){
        gtmEvent._gl = queryParams.get('_gl');
      }

      if (reservationInfo.selectedCategories && reservationInfo.selectedCategories.length > 0) {
        let reservation_category = '';
        let total = 0;
        if (reservationInfo.selectedCategories[0].count) {
          reservationInfo.selectedCategories.map(category => {
            total += parseInt(category.count) * parseInt(category.package_amount);
            reservation_category += category.count + ' ' + category.package_name + ', ';
          })
          gtmEvent.reservation_revenue = total;
          setPaidAmount(total);
        } else {
          reservationInfo.selectedCategories.map(category => {
            reservation_category += category.package_name;
            total += parseInt(reservationInfo.tableFor) * parseInt(category.package_amount)
          })
        }
        gtmEvent.reservation_packages = reservation_category;
        gtmEvent.reservation_revenue = total;
        setPaidAmount(total);
      }
        gtmEvent.reservation_id = response.reservation_id;
        console.log('gtm: ',gtmEvent,response.reservation_id)
        gtmEvent.reservation_data += '(Reservation id: ' + response.reservation_id + ')';
        TagManager.dataLayer({
          dataLayer: gtmEvent
        })
    }

    if (reservationInfo.cid) {
      console.log(atob(reservationInfo.cid));
      const fbpixel_object = {
          mealDate: reservationInfo.date, 
          firstname: reservationInfo.firstName,
          lastname: reservationInfo.lastName,
          email: reservationInfo.email,
          phoneNumber: '+' + reservationInfo.country_isd + ' '+reservationInfo.phone_number,
          partySize: reservationInfo.tableFor
      }
      console.log(fbpixel_object);
      ReactPixel.init(atob(reservationInfo.cid));
      ReactPixel.track("MZ_Booking_Confirmed",fbpixel_object)
    }

    if(response && response.payment_type === 'direct' && partialAmount) {
      setPaidAmount(partialAmount);
    } else {
      setPaidAmount(response.payment_order_amount);
    }
    
    }))
  }


    dispatch(
      updateStateVariable({
        key: "defaultLang",
        value: reservationInfo.language,
      })
    );
  }, [theme]);

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const buttonDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const OutlineButtonDesign = {
    backgroundColor: "transparent",
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    borderColor: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : '',
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : '',
    margin: '15px 0px'
  };

  function onButtonClicked() {
    let query = new URLSearchParams(history.location.search);

    dispatch(resetReservation());
    history.push({
      pathname: '/',
      search: window.location.search,
    })
    history.go(0);
    localStorage.removeItem("reservation_info");
  }

  function timeFormater(time) {
    let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date));
    let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
    // if (is_dst) {
    //   offset += 60;
    // }
    return moment(time, 'HH:mm').add(-offset, 'minutes').format('HH:mm')
  }

  
  return (
    <LayoutContainer
      alignItems="center"
      justifyContent="center"
      className="successPageContainer"
      style={widgetBackgroundColor}
    >
      <Header title={theme.headerTitle && theme.headerTitleValue && defaultLang==='en'? theme.headerTitleValue : ''} />

      {!loaderToggle &&
        <LayoutItem xs={10}>
          <div className="middleContainer">
            <Typography
              variant="h5"
              display="block"
              align="center"
              className="successSubTitle"
              style={colorDesign}
            >
              {props.title}
            </Typography>
            <div className={`actionContainer ${theme.venueGoogleMapUrl ? "actionContainerButton" : ''}`}>
              <Button
                variant="contained"
                fullWidth
                onClick={onButtonClicked}
                style={buttonDesign}
              >{props.buttonText}
              </Button>
              <Button
                variant="outlined"
                fullWidth
                href={theme.venueWebsiteUrl}
                target="_blank"
                style={OutlineButtonDesign}
              >{props.buttonText2}
              </Button>
            </div>
          </div>
          {theme.venueGoogleMapUrl &&
            <iframe width="100%" height="60" src={theme.venueGoogleMapUrl} frameborder="0" style={{ borderRadius: widgetBackgroundColor.borderRadius }}></iframe>
          }
        </LayoutItem>
      }

      {!loaderToggle &&
        <LayoutItem xs={10}>
          <Divider />
          <ReservationDetail
            name={reservationInfo.firstName + ' ' + reservationInfo.lastName}
            description={reservationInfo.reservation_type === 'join_waitlist' ? t("waitlist_booking_details") : t("booking_details")}
            date_time={reservationInfo.date}
            persons={reservationInfo.tableFor}
            referenceCode={reservationInfo.referenceCode}
            total={reservationInfo.total}
            type={reservationInfo.type}
            allergies={reservationInfo.allergies}
            dietary={reservationInfo.dietary}
            occasion={reservationInfo.occasion}
            widget={reservationInfo.widget}
          />
          <Packages
            list={reservationInfo.selectedCategories}
            type={reservationInfo.type}
            vatValue={venueDetails.vat_percentage}
            isVatIncluded={reservationInfo?.payment_settings?.is_vat_included}
            onChoosePackage={() => { }}
            onChooseNumber={() => { }}
            total={reservationInfo.total}
            page_type='payment_success'
          />
        </LayoutItem>
      }

      <LayoutItem xs={10}>
        <Copyright />
      </LayoutItem>

    </LayoutContainer>
  );
}