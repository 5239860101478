import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from "react-redux";

export default function LayoutContainer(props) {
    const {
        defaultLang
    } = useSelector((state) => state.store);
    return (
        <React.Fragment>
            <Grid container dir={defaultLang === 'ar' ? 'rtl' : 'ltr'} style={props.style ? props.style : {}} className={props.className} spacing={props.spacing} justifyContent={props.justifyContent ? props.justifyContent : 'center'} alignItems={props.alignItems ? props.alignItems : 'center'}>
                {props.children}
            </Grid>
        </React.Fragment>
    );
}