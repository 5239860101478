import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import LayoutContainer from "./layout/LayoutContainer";
import LayoutItem from "./layout/LayoutItem";
import { updateProperty } from '../features/store/storeSlice';
import 'react-phone-number-input/style.css';
import './../assets/scss/PhoneNumber.scss';

export default function PhoneNumber(props) {
    const dispatch = useDispatch();
    let [value, setValue] = useState(props.value ? props.value : '');
    let [validNumber, setValidNumber] = useState(true);
    const { countries, venueDetails } = useSelector((state) => state.store);

    function pushToRepeater() {
        if (props.onMouseOut) {

            if (props.setCountryCode && value && parsePhoneNumber(value)) {
                let parsedPhoneNumber = parsePhoneNumber(value);
                let result = countries.filter((country) => {
                    return country.iso === parsedPhoneNumber.country
                });
                props.onMouseOut(result);
            }
        }
        if (props.valueCallback) {
            if (value || value === '') {
                setValidNumber(isValidPhoneNumber(value));
                props.valueCallback(value, props.index);
            }
        }
    }

    function onChangeMobileNumber(value) {
        setValue(value);
        props.setPhone(value);
        if (props.setCountryCode && value && parsePhoneNumber(value)) {
            let parsedPhoneNumber = parsePhoneNumber(value);
            let result = countries.filter((country) => {
                return country.iso === parsedPhoneNumber.country
            });

            if (result.length) {
                dispatch(
                    updateProperty({ key: 'country_name', value: result[0].printable_name })
                );
                dispatch(
                    updateProperty({ key: 'country_id', value: result[0].id })
                );
                dispatch(
                    updateProperty({ key: 'country_isd', value: result[0].isd })
                );
            }
        }
        if (props.onChange) {
            props.onChange(formatPhoneNumber(value));
        }
    }

    function onDeleteNumber() {
        props.deleteCallback(props.index);
    }

    function flags(value) {
        let flagImg = value.flagUrl;
        flagImg = flagImg.replace("{XX}", value.country);
        return (
            <div className="phoneInputDropdown">
                <div className="phoneInputDropdownImg"><img src={flagImg} /></div>
                <div className="phoneInputDropdownCode">{'+' + getCountryCallingCode(value.country)}</div>
            </div>
        )
    }

    const deleteButton = props.index ? <div className="deletePhoneNumber" onClick={onDeleteNumber}><DeleteIcon /></div> : ' ';

    return (
        <React.Fragment>
            <div className={'mobileNumberContainer ' + props.classes}>
                <LayoutContainer spacing={0}>
                    <LayoutItem className="phoneNumberCodeLabel" xs={2}>
                        <label>Area Code</label>
                    </LayoutItem>
                </LayoutContainer>
                <PhoneInput
                    placeholder={props.placeholder ? props.placeholder : ''}
                    value={value}
                    national={value && formatPhoneNumber(value)}
                    international={false}
                    // inputClass="inputPhone"
                    flagComponent={flags}
                    smartCaret={true}
                    defaultCountry={venueDetails.country}
                    onBlur={pushToRepeater}
                    onChange={onChangeMobileNumber}
                />
                {deleteButton}
            </div>
        </React.Fragment>
    );
}