import * as React from 'react';
import Grid from '@material-ui/core/Grid';

export default function LayoutItem(props) {
    function onClickLayout(){
        if(props.onClick)
            props.onClick()
        else
            return '';
    }
    return (
        <React.Fragment>
            <Grid item xs={props.xs ? props.xs : 'auto'} style={props.style ? props.style : {}} md={props.md ? props.md : false} sm={props.sm ? props.sm : false} className={props.className ? props.className : ''} spacing={props.spacing ? props.spacing : 0} onClick={onClickLayout}>
                {props.children}
            </Grid>
        </React.Fragment>
    );
}