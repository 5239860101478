import React from "react";
import { useSelector } from "react-redux";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import "./../../assets/scss/Timer.scss";

export default function Timer(props) {
  const theme = useSelector((state) => state.store.theme);

  const timerDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const time = useSelector((state) => state.store.timer);
  const { hours = 0, minutes = 0, seconds = 60 } = time;
  const [[hrs, mins, secs], setTime] = React.useState([
    hours,
    minutes,
    seconds,
  ]);

  const tick = () => {
    if (hrs === 0 && mins === 0 && secs === 0) {
      if (props.onTimeout) {
        props.onTimeout();
      } else {
        reset();
      }
    }
    else if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59]);
    } else {
      setTime([hrs, mins, secs - 1]);
    }
  };
  function clockIcon() {
    if (!props.inline) {
      return <AccessTimeIcon />;
    }
  }

  const reset = () =>
    setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <div className={props.inline ? "inlineTimerContainer" : " timerContainer"} style={!props.inline ? timerDesign : {}}>
      {clockIcon()}
      <div className="time">{`${hrs ? hrs.toString().padStart(2, "0") + ":" : ""
        }${mins.toString().padStart(2, "0")}:${secs
          .toString()
          .padStart(2, "0")}`}</div>
    </div>
  );
}
