import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Divider, Typography } from '@material-ui/core';
import LayoutItem from "../layout/LayoutItem";
import '../../assets/scss/CancellationSuccess.scss';

function CancellationSuccess() {
    const { t } = useTranslation();
    const { theme } = useSelector((state) => state.store);;

    const style = {
        color: theme.calendarTextColor ? theme.calendarTextColor : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ""
    };

    return (
        <React.Fragment>
            <Box component={'div'} className='cancellationSuccessContainer'>
                <Typography variant="h3" style={style} >{t('your_reservation_is_successfully_cancelled')}</Typography>
                <Typography variant="p" style={style}>{t('we_look_forward_to_seeing_you_soon')}</Typography>
            </Box>
            <LayoutItem xs={12}>
                <Divider />
            </LayoutItem>
        </React.Fragment>
    );
}

export default CancellationSuccess;

