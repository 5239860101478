import * as React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { resetReservation } from "./../../features/store/storeSlice";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import Header from "./Header";
import './../../assets/scss/Success.scss';
import Copyright from './Copyright';

export default function Failed(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { previewMode, theme, defaultLang } = useSelector((state) => state.store);
  const history = useHistory();
  var { title, buttonText, buttonText1, buttonText2 } = props
  if (history.location && history.location.state && history.location.state.title) {
    title = history.location.state.subtitle
  }

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ""
  };

  const buttonDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const OutlineButtonDesign = {
    backgroundColor: "transparent",
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    borderColor: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : '',
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : '',
    margin: '15px 0px'
  };

  function onButtonClicked() {
    dispatch(resetReservation());
    history.push({
      pathname: '/',
      search: window.location.search,
    });
    history.go(0);
  }

  const renderMap = () => {
    if (theme.venueGoogleMapUrl && previewMode === false) {
      return (<iframe width="100%" height="60" src={theme.venueGoogleMapUrl} frameborder="0" style={{ borderRadius: widgetBackgroundColor.borderRadius }}></iframe>);
    } else if (previewMode) {
      return (<iframe width="100%" height="60" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14455.885974746396!2d55.1388336!3d25.0689553!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d4cc83b4dc7%3A0x89540b27c3c0d55b!2sserVme!5e0!3m2!1sen!2sin!4v1679469262989!5m2!1sen!2sin" frameborder="0" style={{ borderRadius: widgetBackgroundColor.borderRadius }}></iframe>);
    } else {
      return '';
    }
  }

  const displayServerMessage = (title) => {
    if (title && title == 'Invalid phone number') {
      return t('invalid_phone_number');
    } else if (title && title == 'Email is not a valid email address') {
      return t('email_is_not_a_valid_email_address');
    }
    else {
      return title;
    }
  }

  return (
    <LayoutContainer
      alignItems="center"
      justifyContent="center"
      className={'failedPageContainer border-radius-0'}
      style={widgetBackgroundColor}
    >
      <Header title={theme.headerTitle && theme.headerTitleValue && defaultLang === 'en' ? theme.headerTitleValue : ''} />
      <LayoutItem xs={10}>
        <div className="middleContainer">
          <Typography
            variant="h5"
            display="block"
            align="center"
            className="successSubTitle failedPageTitle"
            style={colorDesign}
          >
            {displayServerMessage(title)}
          </Typography>
          <div className={`actionContainer ${theme.venueGoogleMapUrl || previewMode ? "actionContainerButton" : ''}`}>
            <Button
              variant="contained"
              fullWidth
              onClick={onButtonClicked}
              style={buttonDesign}
            >{buttonText}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              href={theme.venueWebsiteUrl}
              target="_blank"
              style={OutlineButtonDesign}
            >{buttonText1}
            </Button>
          </div>
          {renderMap()}
        </div>
      </LayoutItem>
      <LayoutItem xs={12}>
        <Button
          className="buttonFix noFix"
          style={buttonDesign}
          variant="contained"
          endIcon={<ArrowForwardIosIcon style={{ color: buttonDesign.color }} />}
          onClick={onButtonClicked}
        >
          {buttonText2}
        </Button>
      </LayoutItem>
      <LayoutItem xs={10} >
        <Copyright />
      </LayoutItem>
    </LayoutContainer>
  );
}