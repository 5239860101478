import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import LayoutContainer from '../layout/LayoutContainer'
import LayoutItem from '../layout/LayoutItem'
import Language from './Language';
import Carousel from "nuka-carousel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

function EventHeader({ details }) {
  const { theme, venueDetails, widgetLanguageOptions } = useSelector((state) => state.store);
  const { innerWidth: width, innerHeight: height } = window;

  const backgroundStyle = {
    backgroundImage: details.settings && details.settings.event_banner_image && details.settings.event_banner_image.length ? 'URL(' + details.settings.event_banner_image[0] + ')' : '',
    height: theme.coverImageUrl && parseInt(theme.coverImage) ? '200px' : '',
    backgroundSize: theme.coverImageUrl && parseInt(theme.coverImage) ? 'cover' : '',
    backgroundColor: !theme.coverImageUrl && parseInt(theme.coverImage) ? '#C8C8C8' : '',
    backgroundPosition: 'center center'
  };

  const headerDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    borderColor: "transparent"
  };

  const dropDownDesign = {
    backgroundColor: "transparent",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    borderColor: "transparent",
    fontWeight: 'bold',
    fontSize: '0.875rem'
  };

  function logoIfExist() {
    if (parseInt(theme.logo) && theme.logoUrl) {
      return (<Grid item xs={12} className='logoContainer eventViewLogoContainer' ><div className='logo'><img src={theme.logoUrl} /></div></Grid>)
    }
    return '';
  }

  function logoOrBackground() {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="logoContainer"
        style={{ boxShadow: '0px 0px 20px #00000029', paddingRight: 0 }}
      >
        <Grid item xs={12} style={backgroundStyle}></Grid>
      </Grid>
    )
  }

  return (
    <LayoutContainer>
      <LayoutItem xs={12} style={{ position: 'relative' }}>
        {widgetLanguageOptions.filter(language => language.is_selected).length > 0 &&
          <div className="headerBlock12" style={{ height: 50, paddingLeft: 10 }}>
            <Language />
          </div>}
        {logoIfExist()}
      </LayoutItem>
      <LayoutItem xs={12}>
        {/* {logoOrBackground()} */}
        {details.settings && details.settings.event_banner_image && details.settings.event_banner_image.length > 0 && 
          <>
            <Carousel
              className={'carouselSlideCount_'+details.settings.event_banner_image.length}
              slidesToShow={1}
              renderBottomCenterControls={() => {
                return false;
              }}
              cellSpacing={10}
              renderCenterLeftControls={({ previousSlide }) => (
                <ChevronLeftIcon
                  className="carouselRenderButtons"
                  onClick={previousSlide}
                />
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <ChevronRightIcon
                  className="carouselRenderButtons"
                  onClick={nextSlide}
                />
              )}
            >
              {details.settings.event_banner_image.map((value, index) => {
                return (
                  <div className='logoContainer coverImage' style={{backgroundImage: 'URL(' + value + ')'}}></div>
                )
              })}
            </Carousel>
          </>
        }
      </LayoutItem>
      <LayoutItem xs={12} style={{ backgroundColor: headerDesign.backgroundColor, color: dropDownDesign.color, paddingTop: 7, paddingBottom: 7 }}><Typography style={dropDownDesign} className="chooseVenueDropdown">{venueDetails.outlet_name}</Typography></LayoutItem>
    </LayoutContainer>
  )
}

export default EventHeader
