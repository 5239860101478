import React, { useEffect } from "react";
import moment from "moment-timezone";
import Carousel from 'nuka-carousel';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, Divider, Typography } from "@material-ui/core";
import LayoutItem from "../layout/LayoutItem";
import LayoutContainer from "../layout/LayoutContainer";
import { updateProperty } from "./../../features/store/storeSlice";
import Header from "../shared/Header";
import "./../../assets/scss/Event.scss";

export default function ListEvents(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { theme, events } = useSelector((state) => state.store);

  const buttonDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const bookButtonDesign = {
    color: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  }

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : "",
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : "",
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  function eventStatus(dateFrom, dateTo) {
    if (
      moment(moment()).isAfter(dateFrom) &&
      moment(moment()).isBefore(dateTo)
    ) {
      return <div className="upcomingLabel">{t("happening_now")}</div>;
    } else if (moment(moment()).isBefore(dateFrom)) {
      return <div className="upcomingLabel">{t("upcoming")}</div>;
    }
    return "";
  }

  function divider(index) {
    if (index) {
      return <Divider />;
    }
  }

  function eventDate(date_from, date_to) {
    let date = '';
    if (date_from && date_to) {
      date =
        t("from") +
        " " +
        moment(date_from).format("dddd MMMM DD YYYY") +
        ' ' + t('till') + ' ' +
        moment(date_to).format("dddd MMMM DD YYYY");
    } else {
      date =
        t("on") +
        " " +
        moment(date_from).format("dddd, MMMM DD YYYY");
    }
    return date;
  }

  useEffect(() => {
    dispatch(updateProperty({ key: "section", value: {} }));
    dispatch(updateProperty({ key: "selectedEvent", value: {} }));
  }, []);

  function onClickBook(event) {
    dispatch(updateProperty({ key: "section", value: event }));
    dispatch(updateProperty({ key: "selectedEvent", value: event }));
    history.push({
      pathname: '/event/' + event.shift_id,
      search: window.location.search
    });
  }

  function bookADay(event, date) {
    let newSelectedEvent = JSON.parse(JSON.stringify(event));
    let result = newSelectedEvent.intervals.filter((interval) => {
      return interval.date === date;
    });
    newSelectedEvent.available_time = result[0].online_active_intervals;
    dispatch(updateProperty({ key: "selectedEvent", value: newSelectedEvent }));
    dispatch(updateProperty({ key: "date", value: date }));
    history.push({
      pathname: '/event/' + event.shift_id,
      search: window.location.search
    })
  }

  function carouselImages(images) {
    if (images) {
      let result = [];
      images.forEach((img) => {
        result = [result, <img src={img} />]
      });
      return result;
    }
  }

  return (
    <LayoutContainer
      style={widgetBackgroundColor}
      alignItems="center"
      justifyContent="center"
      className="eventListContainer"
    >
      <Header getDataFunction={props.getDataFunc} title={theme.headerTitle && theme.headerTitleValue ? theme.headerTitleValue : ''} />
      <LayoutItem xs={12}>
        <Typography
          variant="h4"
          display="block"
          align="center"
          className="pageTitle"
          style={widgetBackgroundColor}
        >
          {t("current_and_symbol_upcoming_events")}
        </Typography>
      </LayoutItem>
      {events.map((value, index) => {
        return (
          <LayoutItem xs={10} key={index}>
            {divider(index)}
            <div className="eventContainer">
              <Carousel>
                {carouselImages(value.shift_banner_image)}
              </Carousel>
              {/* </div> */}
              <div className="eventTitle">{value.shift_metadata_name}</div>
              <div className="eventBookBtn">
                <Button
                  variant="contained"
                  color="primary"
                  style={buttonDesign}
                  onClick={() => onClickBook(value)}
                >
                  {t("book")}
                </Button>
              </div>
              <div className="eventDate">{eventDate(value.date_from, value.date_to)}</div>
              <div className="eventStartingPrice">{value.price}</div>
              <div className="eventDetails">
                {value.description && value.description.length > 250
                  ? value.description.substring(0, 250) + "..."
                  : value.description}
              </div>
              <div className="eventDaysList">
                {
                  value.allowed_days.map((date, index) =>
                    <div key={index} className="eventListDay">
                      <div className="eventDay">
                        {moment(date).format("dddd, MMMM DD YYYY")}</div>
                      <a onClick={() => bookADay(value, date)} style={bookButtonDesign}>{t('book')}</a>
                    </div>)
                }
              </div>
            </div>
          </LayoutItem>
        );
      })}
    </LayoutContainer>
  );
}
