import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Carousel from "nuka-carousel";
import { Button, TextField } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import LayoutContainer from "../layout/LayoutContainer";
import "./../../assets/scss/Reservation.scss";
import LayoutItem from "../layout/LayoutItem";

export default function NumbersBoxes(props) {
  const { t } = useTranslation();
  const { theme, reservation,selectedEvent,defaultLang } = useSelector((state) => state.store);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [customValue, setCustomValue] = useState(null);
  const { innerWidth: width, innerHeight: height } = window;
  const [slidesToShow, setSlidesToShow] = useState(5);
  const [selectedCarouselIndex, setSelectedCarouselIndex] = useState([0]);
  const [changed, setChanged] = useState(false);

  const defaultBoxColor = {
    backgroundColor: theme.calendarBoxColor ? theme.calendarBoxColor : "",
    opacity: theme.defaultBoxOpacity ? theme.defaultBoxOpacity : "",
    color: theme.calendarLinkColor ? theme.calendarLinkColor : "",
    fontFamily: theme.calendarFontFamily?theme.calendarFontFamily:''
  };

  const selectedStyle = {
    color: theme.calendarSelectedColor ? theme.calendarSelectedColor : "",
    backgroundColor: theme.calendarSelectedAreaColor
      ? theme.calendarSelectedAreaColor
      : "",
    opacity: theme.selectedColorOpacity ? theme.selectedColorOpacity : "",
  };

  const style = { ...defaultBoxColor, ...selectedStyle };
  const maxNumber = props.maxNumber ? props.maxNumber : 15;
  function selectNumber(e, i) {
    setChanged(true);
    let value = e.target.outerText ? e.target.outerText : e.target.textContent;
    if(defaultLang)
      value = e.target.outerText.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
    value=parseInt(value);
    setSelectedNumber(value);
    setCustomValue("");
    if (props.onChooseNumber) {
      props.onChooseNumber(value);
    }
  }

  function selectNumberFromField(event) {
    let value = event.target.value;
    setSelectedNumber(value);
    setCustomValue(value);
    if (props.onChooseNumber) {
      props.onChooseNumber(value);
    }
  }

  let numbersBody = [];
  let i = props.min;
  let loopLimit = props.max;
  // let loopLimit = 7 + (i - 1);

  // if(defaultLang==='ar'){
  //   // console.log((123.93).toLocaleString('ar-u-nu-arab'))
  //   for (i; i <= loopLimit; i++) {
  //     let st=JSON.stringify(i.toLocaleString('ar-u-nu-arab'));
  //     numbersBody.push(
  //       <div key={i}>
  //         <Button
  //         disableElevation
  //           fullWidth
  //           variant="contained"
  //           style={i === reservation.tableFor ? style : defaultBoxColor}
  //           className={i === reservation.tableFor ? "selected carouselDate widgetDefaultButton" : "carouselDate widgetDefaultButton"}
  //           key={i}
  //           onClick={(e) => selectNumber(e, i)}
  //           >
  //           <div className="carouselDay">{st}</div>
  //         </Button>
  //       </div>
  //     );
  //   }
  // }else{
    for (i; i <= loopLimit; i++) {
      numbersBody.push(
        <div key={i}>
          <Button
          disableElevation
            fullWidth
            variant="contained"
            style={i === reservation.tableFor ? style : defaultBoxColor}
            className={i === reservation.tableFor ? "selected carouselDate widgetDefaultButton" : "carouselDate widgetDefaultButton"}
            key={i}
            onClick={(e) => selectNumber(e, i)}
            >
            <div className="carouselDay" title={i}>{defaultLang==='ar'?JSON.parse(JSON.stringify(i.toLocaleString('ar-u-nu-arab'))):i}</div>
          </Button>
        </div>
      );
    }
  // }

  const handleCarouselClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const textField = document.getElementById("test-textfield");
    if (textField) {
      textField.focus();
    }
  };
  
  function numbersField() {
    if ((loopLimit < 7 || props.max - props.min < 7) && !props.limited) {
      return ('');
    } else {
      let val = !reservation.tableFor ? '' : customValue
      return (
        <div className="numberBoxFieldSquare widgetDefaultButton">
          <div
            style={val ? style : defaultBoxColor}
            className="numberBoxField"
            >
            <div className="carouselDay">
              <TextField
              id="test-textfield"
                placeholder={t("type_number_symbol")}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  style: {backgroundColor: 'inherit', maxHeight:50},
                }}
                onClick={handleCarouselClick}
                value={val}
                onChange={(e) => selectNumberFromField(e)}
                />
            </div>
          </div>
        </div>
      );
    }
  }
  
  if(props.limited){
    numbersBody.push(numbersField())
  }

  useEffect(() => {
    if (width < 600 && width > 500) {
      setSlidesToShow(5);
    } else if (width <= 500) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(5);
    }
  }, [width]);

  function changeMaxCover() {
    let obj = {};
    let result =0;
    if (selectedEvent.event_id) {
      obj = selectedEvent;
      result = obj.max_guest_count;
    }
    else {
      obj = reservation.section;
      result = obj.max_guest_count < reservation.section.selectedTime.capacity ? obj.max_guest_count : reservation.section.selectedTime.capacity;
    }

    loopLimit=result;
    // setSelectedMaxCovers(result);
  }


  useEffect(() => {
    if(reservation.tableFor && !changed){
      changeMaxCover()
      let index= reservation.tableFor - props.min;
      
      if(index>=5 && numbersBody>slidesToShow)
        setSelectedCarouselIndex(index);
      if(loopLimit<reservation.tableFor && props.limited){
        setCustomValue(reservation.tableFor);
      }
    }
  }, [reservation.tableFor])

  return (
    <div className="numberBox">
      {/* {true ? (
        <LayoutContainer
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          className={props.className ? props.className : ""}
        >
          <LayoutItem xs={12}>
          {numbersBody}
          </LayoutItem>
          {numbersField()}
        </LayoutContainer>) 
        : ( */}
        <LayoutContainer
          alignItems="center"
          justifyContent="center"
          className="carouselNumberBox"
        >
          <LayoutItem xs={12}>
            
          <Carousel
            slideIndex={selectedCarouselIndex}
            slidesToScroll={2}
            slidesToShow={slidesToShow}
            renderBottomCenterControls={() => {
              return false;
            }}
            cellSpacing={0}
            wrapAround={false}
            renderCenterLeftControls={({ previousSlide }) => (
              <ChevronLeft
                className={(numbersBody.length > slidesToShow) ? "carouselRenderButtons" : "carouselRenderButtons hideButton"}
                onClick={previousSlide}
                />
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <ChevronRight
                className={(numbersBody.length > slidesToShow) ? "carouselRenderButtons" : "carouselRenderButtons hideButton"}
                onClick={nextSlide}
                />
            )}
            withoutControls={false}
            transitionMode="scroll"
            cellAlign="left"
            heightMode="max"
            >
            {numbersBody}
          </Carousel>
        </LayoutItem>
        </LayoutContainer>
        {/* )} */}
    </div>
  );
}
