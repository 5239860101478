import { useSelector } from "react-redux";
import Logo from "../../assets/logo-colored.svg"

export default function Copyright() {
    const { theme } = useSelector((state) => state.store);
    const colorDesign = {
        color: theme.calendarTextColor ? theme.calendarTextColor : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    };

    return (
        <div className={'copyright'}>
            Powered by <a href="https://www.servmeco.com/" target="_blank" style={colorDesign}><img src={Logo} alt="logo" /></a>
        </div>
    );
}