import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@material-ui/core";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { IconButton } from '@material-ui/core';
import { ReactComponent as PeopleIcon } from '../../assets/svg/persons.svg';
import { ReactComponent as PersonIcon } from '../../assets/svg/person.svg';
import { ReactComponent as DurationIcon } from '../../assets/svg/duration.svg';

export default function PackagesList(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { theme, reservation, currency, selectedEvent } = useSelector((state) => state.store);
  const [selectedPackage, setSelectedPackage] = useState(reservation.selectedCategories ? reservation.selectedCategories : []);
  const [selectedPackageName, setSelectedPackageName] = useState([]);

  const convertToRGB = (haxCode, opacity) => {
    var aRgbHex = haxCode.match(/.{1,2}/g);
    var rgbaWithOpacity = `rgba(${parseInt(aRgbHex[0], 16)}, ${parseInt(aRgbHex[1], 16)}, ${parseInt(aRgbHex[2], 16)}, ${opacity})`;
    return rgbaWithOpacity;
  }

  const defaultBoxColor = { backgroundColor: "transparent", color: "#2A3F54", borderColor: "#EBEEF4" };

  const selectedStyle = {
    backgroundColor: theme.calendarSelectedAreaColor ? convertToRGB(theme.calendarSelectedAreaColor.replace('#', ''), 0.1) : "",
    borderColor: theme.calendarSelectedAreaColor ? theme.calendarSelectedAreaColor : ""
  };

  const labelDesign = {
    color: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : ""
  };

  const style = { ...defaultBoxColor, ...selectedStyle };

  function onSelectPackage(value) {
    // if (props.type === 'categories') {
    let exist = selectedPackage.filter((item) => item.package_name === value.package_name && item.package_amount === value.package_amount)
    if (exist.length) {
      setSelectedPackage(
        selectedPackage.filter((item) => item.package_name !== value.package_name || item.package_amount !== value.package_amount)
      );
      setSelectedPackageName(
        selectedPackageName.filter((item) => item !== value.package_name || item.package_amount !== value.package_amount)
      );
    } else {
      let vals = JSON.parse(JSON.stringify(value));
      vals.count = 1;
      setSelectedPackage((selectedPackage) => [
        ...selectedPackage,
        vals,
      ]);

      setSelectedPackageName((selectedPackageName) => [
        ...selectedPackageName, value.package_name,
      ]);
    }
    // } else {
    //   setSelectedPackageName([
    //     value.package_name,
    //   ]);
    //   setSelectedPackage([
    //     { name: value.package_name, count: 1, amount: value.package_amount },
    //   ]);

    // }
    if (props.onChoosePackage) {
      props.onChoosePackage(value);
    }
  }

  function selectNumberFromField(event, index, selected, actionType = '') {
    let value = event.target.value;
    let selectedPack = JSON.parse(JSON.stringify(selectedPackage))
    let res = selectedPack.filter((val) => {
      return val.package_name === selected.package_name && val.package_amount === selected.package_amount
    });
    if (res.length) {
      let indexOfSelected = selectedPack.indexOf(res[0]);
      if (actionType === 'inc')
        value = parseInt(selectedPack[indexOfSelected].count) + 1;
      else if (actionType === 'dec')
        value = parseInt(selectedPack[indexOfSelected].count) - 1;
      if (value > 0) {
        selectedPack[indexOfSelected].count = value;
        setSelectedPackage(selectedPack);
        if (props.onChooseNumber) {
          props.onChooseNumber(value, index, selected);
        }
      }
      if (value == 0) {
        onSelectPackage(selectedPack[indexOfSelected])
      }
    }
  }

  function getSelectedValue(value) {
    let res = selectedPackage.filter((val) => {
      return val.package_name === value.package_name
    });
    return res.length ? res[0].count : '';
  }

  function checkIfSelected(value) {
    let res = selectedPackage.filter((val) => {
      return val.package_id === value.package_id
    });
    return res.length;
  }

  function number_format(number, decimals = 0, decPoint = '.', thousandsSep = '') {
    const multiplier = Math.pow(10, decimals);
    const roundedNumber = Math.round(number * multiplier) / multiplier;
    const parts = roundedNumber.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);
    return parts.join(decPoint);
  }


  const displayPrice = (amount, guest) => {
    let vat_percentage = props.vatValue;
      let isVatIncluded = reservation.section.payment_group ? reservation.section.payment_group.is_vat_included : props.isVatIncluded ? props.isVatIncluded : false;
      
    if (selectedEvent.event_id) {
      isVatIncluded = selectedEvent.payment_group ? selectedEvent.payment_group.is_vat_included : false;
    }
    let propVat=props.isVatIncluded;
    if(!props.isVatIncluded)
      propVat=reservation.section.payment_group ? reservation.section.payment_group.is_vat_included : false;
    if ((!isVatIncluded || !propVat) && vat_percentage && (vat_percentage !== '' || vat_percentage != 0)) {
      amount = Number(amount);
      let tempAmount = 0;
      tempAmount = amount * (vat_percentage / 100);
      amount = amount + tempAmount;
      amount = number_format(amount, 2);

    }
    // if (history.location.pathname === '/payment-success' && props.type === 'packages') {
    //   return (<span style={labelDesign}>{currency}&nbsp;{props.total}</span>);
    // } else if (history.location.pathname === '/payment-success' && props.type === 'categories') {
    //   return (<Box component={'div'}><span className="packageName">{guest}&nbsp;x&nbsp;</span><span style={labelDesign}>{currency}&nbsp;{amount}</span></Box>);
    // } else {
      return (<span style={labelDesign}>{currency}&nbsp;{amount}</span>);
    // }
  }

  const displayDuration = (duration) => {
    if (reservation.duration) {
    let durationHours = Math.floor(duration / 60);
    let durationMins = duration % 60;
    let result=durationHours?durationHours+'hr':'';
    if(durationMins) result=result+' '+durationMins+'min';
    return <Box component={'div'} className="packageDuration"><DurationIcon /> {result}</Box>;
    }else {
      return '';
    }
    /*let calDuration = duration / 60;
    if (reservation.duration) {
      calDuration = calDuration <= 1 ? calDuration + ' ' + t("hour") : calDuration + ' ' + t("hours")
      return <Box component={'div'} className="packageDuration"><DurationIcon /> {calDuration}</Box>
    } else {
      return '';
    }*/
  }

  return (
    <LayoutContainer spacing={1} className="packages" alignItems="center" justifyContent="center">
      {props.list.map((value, index) => {
        return (
          <LayoutContainer key={`packages-${index}`} className="packageContainer">
            <LayoutItem
              md={checkIfSelected(value) && props.type === 'categories' && history.location.pathname !== '/payment-success' ? 9 : 12}
              style={checkIfSelected(value) ? style : defaultBoxColor}
              className={checkIfSelected(value) ? "package selected" : "package"}
              onClick={(e) => {
                if (history.location.pathname !== '/payment-success') {
                  onSelectPackage(value);
                }
              }}
            >
              <Box component={'div'} className="packageItem">
                <LayoutItem xs={12} md={12} className="packageDescription">
                  <Box component={'div'} className="packageIcon">{value.booking_per === 'person' ? <PersonIcon /> : <PeopleIcon />}</Box>
                  <Box component={'div'} className="packageDetailsContainer">
                    <Box component={'div'} className="packageName">{value.package_name} {value.max_guest_count != 0 && (<span className="packageMaxGuests">({`${t('max')} ${value.max_guest_count} ${value.max_guest_count > 1 ? t('guests') : t('guest')}`})</span>)}</Box>
                    <Box component={'div'} className="packageDetails">{value.package_description}</Box>
                    {displayDuration(value.duration)}
                  </Box>
                </LayoutItem>
                <LayoutItem xs={6} md={4} className="packagePrice">
                  { displayPrice(value.package_amount, value.package_count)}
{history.location.pathname !== '/payment-success' && <Box component={'div'} className="pricePer">/{t(value.booking_per === 'reservation' ? 'group' : 'person')}</Box>}
                </LayoutItem>
              </Box>
            </LayoutItem>
            <LayoutItem
              md={3}
              style={style}
              className={
                checkIfSelected(value) && props.type === 'categories' && history.location.pathname !== '/payment-success'
                  ? "packageField package selected numberBoxField"
                  : "packageField package"
              }
            >
              <Box component={'div'} className="carouselDay ">
                <IconButton
                  size='small'
                  style={style}
                  aria-label="Decrement"
                  onClick={(e) => selectNumberFromField(e, index, value, 'dec')}
                  className={''}
                >
                  <RemoveIcon
                    style={style} fontSize='small' />
                </IconButton>


                <TextField
                  placeholder={t("type_number_symbol")}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 10,
                    }
                  }}
                  value={checkIfSelected(value) ? getSelectedValue(value) : 1}
                  onChange={(e) => selectNumberFromField(e, index, value)}
                />

                <IconButton
                  size='small'
                  style={style}
                  aria-label="Increment"
                  onClick={(e) => selectNumberFromField(e, index, value, 'inc')}
                  className={''}
                >
                  <AddIcon
                    style={style} fontSize='small' />
                </IconButton>
              </Box>
            </LayoutItem>
          </LayoutContainer>
        );
      })}
    </LayoutContainer>
  );
}
