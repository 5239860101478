import React, { useState, useEffect } from "react";
import Carousel from "nuka-carousel";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import StarIcon from '@material-ui/icons/Star';
import "./../../assets/scss/Reservation.scss";
import { Button } from "@material-ui/core";
import 'moment/locale/ar';
import 'moment/locale/ru';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/pt';

function CompactCarouselDate(props) {
  const { t } = useTranslation();
  const { defaultLang, reservation, theme } = useSelector((state) => state.store);
  moment.locale('en');
  const [selectedDate, setSelectedDate] = useState(props.data ? props.data[0] : '');
  const { innerWidth: width, innerHeight: height } = window;
  const [selectedCarouselIndex, setSelectedCarouselIndex] = useState([0]);
  const [dateChanged, setDateChanged] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(5);
  const [dates, setDates] = useState([]);

  const defaultBoxColor = {
    backgroundColor: theme.calendarBoxColor ? theme.calendarBoxColor : '',
    opacity: theme.defaultBoxOpacity ? theme.defaultBoxOpacity : '',
    color: theme.calendarLinkColor ? theme.calendarLinkColor : '',
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ''
  };

  const selectedStyle = {
    color: theme.calendarSelectedColor ? theme.calendarSelectedColor : '',
    backgroundColor: theme.calendarSelectedAreaColor ? theme.calendarSelectedAreaColor : '',
    opacity: theme.selectedColorOpacity ? theme.selectedColorOpacity : ''
  };
  const style = { ...defaultBoxColor, ...selectedStyle };
  const todayDate = moment().format("YYYY-MM-DD");

  const formatDate = (date, format) => {
    return moment(date).locale(defaultLang).format(format);
  };

  function checkIfEvent(arr) {
    // let result= arr.shifts.filter((ar)=>{
    //   return ar.type==='event';
    // })
    // return result.length?<StarIcon className="starIcon"/>:'';
  }
  function selectDate(date) {
    setDateChanged(true);
    setSelectedDate(date);
    if (props.onChooseDate) {
      props.onChooseDate(date);
    }
  }


  useEffect(() => {
    if (!dateChanged) {
      let selected_index = props.data.indexOf(formatDate(reservation.date, "YYYY-MM-DD")) < props.data.length - 5 ? props.data.indexOf(formatDate(reservation.date, "YYYY-MM-DD")) : props.data.length - 5;
      if (selected_index >= 5) {
        setSelectedCarouselIndex(selected_index)
      }
    }
  }, [reservation.date])

  useEffect(() => {
    if (width < 600 && width > 500) {
      setSlidesToShow(4);
    } else if (width <= 500) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(5);
    }
  }, [width]);

  useEffect(() => {
    setFormatedData();
  }, [defaultLang]);


  useEffect(() => {
    setFormatedData();
  }, [props.data]);

  function setFormatedData() {
    let formatedData = [];
    props.data.forEach(dat => {
      formatedData.push({
        value: dat,
        day: formatDate(dat, "ddd"),
        monthDay: formatDate(dat, "MMM D")
      })
    })
    setDates(formatedData);
  }

  return (
    <div id="eventCarouselDate" className="dateSlider">
      <LayoutContainer
        spacing={1}
        alignItems="center"
        justifyContent="center"
        className="sectionTable"
      >
        <LayoutItem xs={12}>
          <Carousel
            slideIndex={selectedCarouselIndex}
            slidesToShow={slidesToShow}
            slidesToScroll={2}
            withoutControls={false}
            renderBottomCenterControls={() => {
              return false;
            }}
            cellSpacing={0}
            renderCenterLeftControls={({ previousSlide }) => (
              <ChevronLeftIcon
                className={(props.data.length > slidesToShow) ? "carouselRenderButtons" : "carouselRenderButtons hideButton"}
                onClick={previousSlide}
              />
            )}
            renderCenterRightControls={({ nextSlide, currentSlide }) => (
              <ChevronRightIcon
                className={(props.data.length > slidesToShow) ? "carouselRenderButtons" : "carouselRenderButtons hideButton"}
                onClick={nextSlide}
              />
            )}
          >
            {dates.map((value, index) => {
              return (
                // <div id="carousel-dates" className="widgetDefaultButton">
                //   <div
                //     className={
                //       formatDate(value, "YYYY-MM-DD") === formatDate(reservation.date, "YYYY-MM-DD")
                //         ? "selected carouselDate"
                //         : "carouselDate"
                //     }
                //     style={{ ...formatDate(reservation.date, "YYYY-MM-DD") === formatDate(value, "YYYY-MM-DD") ? style : defaultBoxColor }}
                //     key={index}
                //     onClick={(e) => selectDate(value)}
                //   >
                //     {checkIfEvent(value)}
                //     <div className="carouselDayName">
                //       {formatDate(value, "ddd")}
                //     </div>
                //     <div className="carouselMonth">
                //       {formatDate(value, "MMM D")}
                //     </div>
                //   </div>
                // </div>
                <div key={index} className="">
                  <Button
                    variant="contained"
                    onClick={(e) => selectDate(value.value)}
                    fullWidth
                    disableElevation
                    style={formatDate(reservation.date, "YYYY-MM-DD") === formatDate(value.value, "YYYY-MM-DD") ? style : defaultBoxColor}
                    // className={
                    //   reservation.time === start_time
                    //     ? "selected iWannaHaveButton widgetDefaultButton"
                    //     : " iWannaHaveButton widgetDefaultButton"
                    // }
                    className={
                      formatDate(value.value, "YYYY-MM-DD") === formatDate(reservation.date, "YYYY-MM-DD")
                        ? "selected carouselDate widgetDefaultButton"
                        : "carouselDate widgetDefaultButton"
                    }
                  >
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="carouselDayName">
                        {value.day}
                      </div>
                      <div className="carouselMonth">
                        {value.monthDay}
                      </div>
                    </span>
                  </Button>
                </div>
              );
            })}
          </Carousel>
        </LayoutItem>
      </LayoutContainer>
    </div>
  );
}

export default CompactCarouselDate;
