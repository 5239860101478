import * as React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link, Typography } from '@material-ui/core';
import { ReactComponent as ArrowBackIcon } from '../../assets/svg/back-arrow.svg';
import '../../assets/scss/Back.scss';

export default function Back({ link }) {
    const history = useHistory();
    const theme = useSelector((state) => state.store.theme);
    const colorDesign = {
        color: theme.calendarSelectedAreaColor ? theme.calendarSelectedAreaColor : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ""
    };

    function onclickLink() {
        if(link.link){
       
            history.push({
                pathname: link.link,
                search: window.location.search
            });
        }else{
     
            history.goBack();
        }
    }

    return (
        <Link onClick={() => onclickLink()} className="backButton">
            <Typography variant="caption" display="block" style={colorDesign}>
                <ArrowBackIcon /> {link.text}
            </Typography>
        </Link>
    );
}