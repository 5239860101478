import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem, Select, TextField, Typography,OutlinedInput } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import Logo from "./Logo";
import EventCarousel from '../event/EventCarousel';
import Copyright from "./Copyright";
import Language from "./Language";
import '../../assets/scss/Header.scss';

export default function Header(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const oid = query.get("oid") ? query.get("oid") : venuesList[0].id;
  const [toggle, setToggle] = useState(true);
  const { theme, widgetLanguageOptions, previewMode, highlightedEvents, venueDetails, venuesList,defaultLang } = useSelector((state) => state.store);
  const [title, setTitle] = useState(t(props.title ? props.title : ''));
  const [selectedVenue, setSelectedVenue] = useState("");

  const dropDownDesign = {
    backgroundColor: "transparent",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    borderColor:"transparent",
    fontWeight: 'bold',
    fontSize:'0.875rem'
  }; 
  const headerDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    borderColor:"transparent"
  };

  function logo() {
   /* if (parseInt(theme["eventCarouselPosition"]) === 0 && (highlightedEvents.length || previewMode)) {
      return (
        <LayoutContainer>
          <LayoutItem xs={12} className="eventCarouselContainer ">
            <Language />
          
         
          </LayoutItem>
        </LayoutContainer>
      );
    } else {*/
      return <Logo />;
   // }
  }

  function eventCarousel() {
    if (parseInt(theme["eventCarouselPosition"]) === 0 && (highlightedEvents.length || previewMode)) {
      return (
        <LayoutContainer>
          <LayoutItem xs={12} className="eventCarouselContainer eventCarouselOnTop">
       
            
           { <EventCarousel getDataFunc={props.getDataFunction}
              data={previewMode ? [{ title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }, { title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }, { title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }, { title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }]: highlightedEvents}
      />}
          </LayoutItem>
        </LayoutContainer>
      );
    } 
  }

  function titleToggle() {
    if (theme.headerTitle && theme.headerTitleValue && defaultLang ==='en' && !props.errorMessage) {
      return (
        <LayoutItem xs={12}>
          {toggle ? (
            <Typography
              variant="h4"
              display="block"
              align="center"
              className="mainTitle widgetTitle"
              style={{fontFamily:headerDesign.fontFamily}}
              onDoubleClick={() => {
                setToggle(!previewMode);
              }}
            >
              {theme.headerTitle && theme.headerTitleValue ? theme.headerTitleValue : title}
            </Typography>
          ) : (
            <TextField
              value={title}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === 'Escape') {
                  setToggle(true)
                  event.preventDefault()
                  event.stopPropagation()
                }
              }}
              onChange={e => { setTitle(e.target.value) }}
            />
          )}
        </LayoutItem>
      )
    } else {
      return ('');
    }
  }

  function handleVenueChange(e) {
    let oid = e.target.value;
    setSelectedVenue(oid);
    window.location.href = "/?oid=" + oid;
  }

  function ascendingName(a, b) {
    return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1;
  }

  function chooseVenueField() {
    let listOfVenues=JSON.parse(JSON.stringify(theme.venuesList));
    if (parseInt(theme.multipleVenues)) {
      let rss=listOfVenues.filter((venue)=>{
        return venue.outlet_id==oid;
      });
      if(!rss.length){
        listOfVenues.push({outlet_id:oid,value:venueDetails.outlet_name})
      }
      return (
        <LayoutItem xs={12} className="chooseVenueDropdownContainer" style={{ backgroundColor: headerDesign.backgroundColor }}>
          <FormControl className="chooseVenueDropdown">
            <label style={dropDownDesign} className="venueDropdownLabel">{t('view_other_locations')} </label>
            <Select
              labelId="venue-dropdown"
              id="demo-simple-select"
              IconComponent={props => (
                <i {...props}>
                  <ArrowDropDownIcon style={{ color: dropDownDesign.color }} />
                </i>
              )}
              value={oid}
              disabled={previewMode ? true : false}
              onChange={handleVenueChange}
              style={dropDownDesign}
              variant="outlined"
              className="multipleVenuesSelection"
            >
              {listOfVenues.length > 0 && listOfVenues.slice().sort(ascendingName).map((venue, key) => (
                <MenuItem key={`venues-${key}`} value={venue.outlet_id}>{venue.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </LayoutItem>
      );
    } else {
      return <LayoutItem xs={12} style={{ backgroundColor: headerDesign.backgroundColor, color:dropDownDesign.color, paddingTop:7, paddingBottom: 7 }}><Typography style={dropDownDesign} className="chooseVenueDropdown">{venueDetails.outlet_name}</Typography></LayoutItem>;
    }
  }

  return (
    <LayoutContainer
      alignItems="center"
      justifyContent="center"
    >
      <LayoutItem xs={12}>
        {logo()}
      </LayoutItem>
      {chooseVenueField()}
{eventCarousel()}

     {/* <EventCarousel getDataFunc={props.getDataFunction}
              data={previewMode ? [{ title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }, { title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }, { title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }, { title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }]: highlightedEvents}
            />*/}

      {history.location.pathname !== '/success' && history.location.pathname !== '/failed' && history.location.pathname !== '/payment-success' && history.location.pathname !== '/payment-failed' && !history.location.pathname.includes('/reservation') && titleToggle()}
     
    </LayoutContainer>
  );
}
