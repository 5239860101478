import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { decrementLoader, incrementLoader, updateStateVariable } from "../../features/store/storeSlice";
import { getReservationByUuid, auth, cancelReservation } from "../../features/store/api";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import Header from "../shared/Header";
import GuestReservationDetail from "./GuestReservationDetail";
import CancellationReason from "./CancellationReason";
import CancellationSuccess from "./CancellationSuccess";
import CancellationFailed from "./CancellationFailed";
import Copyright from "../shared/Copyright";
import "../../assets/scss/Cancel.scss";

export default function Cancel(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.store);
  const query = new URLSearchParams(props.location.search);
  const outletId = query.get("oid");
  const cancellationCode = query.get("token");
  const [data, setData] = useState('Change of plan');
  const [errors, setErrors] = useState('');
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reservation, setReservation] = useState({});

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor ? theme.widgetBackgroundColor : '',
    borderRadius: theme.widgetBorderRadius ? parseInt(theme.widgetBorderRadius) : '',
  };

  const buttonDesign = {
    backgroundColor: "#C1756D",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const disableButtonDesign = {
    backgroundColor: "#b6a7a7",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const useStyles = makeStyles((theme) => ({
    buttonProgress: {
      color: theme.calendarSelectedAreaColor,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    }
  }));

  const getReservationDetail = () => {
    dispatch(incrementLoader());
    let path = location.pathname.split("/reservation/");

    if (path.length > 1) {
      let reservationUID = path[1].split("/")[0];
      getReservationByUuid(reservationUID, outletId).then((response) => {
        dispatch(decrementLoader());
        setDataLoaded(true);
        if (response.status === 'success') {
          setReservation(response.result);
        } else {
          setFailed(true);
          if (response && response.result && response.result.error_code && response.result.error_code === 6003) {
            setErrors('');
          } else {
            setErrors(response.message);
          }
        }
      });
    }
  }

  const handleCancelReservation = () => {
    setErrors('');
    if (!data) {
      setErrors('Other reason is required');
      return;
    }
    setLoading(true);
    cancelReservation(reservation.reservation_id, cancellationCode, data).then((response) => {
      setLoading(false);
      if (response.status === 'success') {
        setSuccess(response.result);
      } else {
        setFailed(true);
      }
    });
  }

  useEffect(() => {
    auth().then((response) => {
      dispatch(
        updateStateVariable({
          key: "accessToken",
          value: response.access_token,
        })
      );
      getReservationDetail();
    });
  }, []);

  const classes = useStyles();

  return (
    dataLoaded &&
    <Box component={'div'} className="cancelContainer"
      style={widgetBackgroundColor}>
      <Header title={theme.headerTitle && theme.headerTitleValue ? theme.headerTitleValue : ''} />
      <Box component={'div'}>
        <LayoutContainer
          alignItems="center"
          justifyContent="center"
        >
          <LayoutItem xs={10}>
            {success && <CancellationSuccess />}
            {failed && <CancellationFailed errors={errors} />}
            {!success && !failed && (<React.Fragment>
              <GuestReservationDetail reservation={reservation} />
              <CancellationReason setData={setData} errors={errors} />
            </React.Fragment>)}
          </LayoutItem>
        </LayoutContainer>
      </Box>
      <LayoutContainer
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        {!success && !failed && <LayoutItem xs={12} className="nextButtonContainer">
          <Button
            className="buttonFix"
            variant="contained"
            color="primary"
            disabled={loading}
            style={loading ? disableButtonDesign : buttonDesign}
            onClick={handleCancelReservation}
          >
            {t("cancel_my_reservation")}
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </LayoutItem>}
        <LayoutItem xs={10}>
          <Copyright />
        </LayoutItem>
      </LayoutContainer>
    </Box>
  );
}
