import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Chip, FormControl, InputAdornment, InputLabel, MenuItem, Select } from "@material-ui/core";
import { LocalOffer } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { updateTags, updateSelectedTags } from "../../features/store/storeSlice";

export default function TagsFieldSelect(props) {
  const dispatch = useDispatch();
  const { widgetTags, allergiesTags, occasionsTags, dietaryTags, selectedTags, theme } = useSelector((state) => state.store);
  const [open, setOpen] = React.useState(false);

  const tags = {
    widget: widgetTags ? widgetTags : [],
    allergies: allergiesTags ? allergiesTags : [],
    occasion: occasionsTags ? occasionsTags : [],
    dietary: dietaryTags ? dietaryTags : [],
  };

  function onAddTag(value) {

    let tagExist = [];
    let concatenatedIds = [];

    for (var i = 0; i <= value.length; i++) {
      tagExist = tags[props.name].filter((element) => {
        return value[i] === element.name;
      });

      if (tagExist.length) {
        concatenatedIds.push(tagExist[0].id);
      }
    }

    dispatch(
      updateTags({ key: props.name, value: concatenatedIds })
    );

    dispatch(
      updateSelectedTags({ key: props.name, value: value })
    );

    if (props.onChangeNote) {
      props.onChangeNote(props.name, value[value.length - 1]);
    }
  }

  function getTagColor(value) {
    let result = [];
    result = tags[props.name].filter((element) => {
      return element.name === value;
    });
    if (result.length) {
      return {
        backgroundColor: result[0].color,
        color: '#fff'
      }
    }
    return {}
  }

  const useStyles = makeStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: 5,
      "& .MuiInputLabel-root.Mui-focused": {
        color: theme.calendarTextColor ? theme.calendarTextColor : ""
      },
    },
    select: {
      "& .MuiSelect-selectMenu": {
        display: "flex",
        flexWrap: "wrap",
        whiteSpace: "normal",
        minHeight: 29,
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    }
  });

  const classes = useStyles();

  const handleDelete = (option) => {
    let newTags = [];
    newTags = selectedTags[props.name].filter((el) => {
      return el !== option;
    });

    let concatenatedIds = [];
    let tagExist = [];
    for (var i = 0; i <= newTags.length; i++) {
      tagExist = tags[props.name].filter((element) => {
        return newTags[i] === element.name;
      });
      if (tagExist.length) {
        concatenatedIds.push(tagExist[0].id);
      }
    }

    dispatch(
      updateTags({ key: props.name, value: concatenatedIds })
    );

    dispatch(
      updateSelectedTags({ key: props.name, value: newTags })
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl className={classes.root} variant={props.variant ? props.variant : ''} fullWidth>
      <Select
        className={classes.select}
        id="demo-simple-select"
        multiple
        value={selectedTags[props.name]}
        onChange={(event) => {
          onAddTag(event.target.value);
          setOpen(false);
        }}
        startAdornment={
          <InputAdornment position="start">
            <LocalOffer size={20} />
          </InputAdornment>
        }
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        displayEmpty={true}
        renderValue={(tags) => {
          if (tags.length > 0) {
            return (
              tags.map((option, index) => (
                <Chip
                  key={index}
                  style={getTagColor(option)} label={option}
                  className={classes.chip}
                  onDelete={(e) => {
                    handleDelete(option);
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))
            )
          } else {
            return <p className='placeholder'>{props.label}</p>
          }
        }
        }
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          PaperProps: {
            style: {
              maxHeight: 200,
            }
          },
          getContentAnchorEl: null
        }}
      >
        {tags[props.name].length > 0 && tags[props.name].map((option, index) => (
          <MenuItem key={`tags-${index}`} value={option.name}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
