import React from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router";
import Login from "./components/Login";
import Payment from "./components/Payment";
import PaymentPackage from "./components/shared/PaymentPackage";
import Success from "./components/shared/Success";
import PaymentSuccess from "./components/shared/PaymentSuccess";
import PaymentFailed from "./components/shared/PaymentFailed";
import Failed from "./components/shared/Failed";
import EventList from "./components/event/List";
import EventDetails from "./components/event/View";
import Preview from "./components/shared/Preview";
import LayoutContainer from "./components/layout/LayoutContainer";
import LayoutItem from "./components/layout/LayoutItem";
import Make from "./components/reservation/Make";
import ViewEventDetails from "./components/reservation/ViewEventDetails";
import Cancel  from "./components/reservation/Cancel";
import { useState, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory, useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import {
  auth,
  getAllowedDays,
  getVenueSettings,
  getDummyData,
  getWidgetBootstrap,
  getShifts,
  getShiftById,
  getEventById,
  getEventTimeslotsByDate,
} from "./features/store/api";
import { useDispatch } from "react-redux";
import {
  updateStateVariable,
  decrementLoader,
  incrementLoader,
  themeHandler,
  updateProperty,
  defaultLang,
  updateNote,
  updateTagNote
} from "./features/store/storeSlice";
import Loader from "./components/shared/Loader";
import Jsona from "jsona";
import Timer from "./components/shared/Timer";
import PreviewLine from "./components/PreviewLine";
import moment from "moment-timezone";
import "moment-timezone";
import TagManager from 'react-gtm-module';

require("dotenv").config();
var WebFont = require("webfontloader");

function App(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dataFormatter = new Jsona();
  const [errorMessage, setErrorMessage] = useState("");
  const { font_initialized, gtm_initialized, previewMode, previewLineLinks, reservation, theme, time, timerToggle, venueDetails, eventDetails,defaultLang } = useSelector(
    (state) => state.store
  );

  const dispatch = useDispatch();
  if (theme.googleTagsManager && theme.googleTagsManagerId) {
    const tagManagerArgs = {
      gtmId: theme.googleTagsManagerId
    }
    if (!gtm_initialized) {
      TagManager.initialize(tagManagerArgs)
      dispatch(
        updateStateVariable({
          key: "gtm_initialized",
          value: true,
        })
      );
    }
  }
  if (theme.facebookPixel) {
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(theme.facebookPixelId, advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view
  }
  useEffect(() => {
    if (theme.calendarFontFamily && !font_initialized) {
      WebFont.load({
        google: {
          families: [theme.calendarFontFamily],
        },
      });
      dispatch(
        updateStateVariable({
          key: "font_initialized",
          value: true,
        })
      );
    }
  }, [theme.calendarFontFamily])
  const appDesgin = {
    backgroundColor: theme.overlayBackgroundColor
      ? theme.overlayBackgroundColor
      : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    backgroundImage:
      theme.overlayBackgroundImage && theme.overlayBackgroundImageUrl
        ? "url(" + theme.overlayBackgroundImageUrl + ")"
        : "",
    backgroundPosition:
      theme.overlayBackgroundImage && theme.overlayBackgroundImageUrl
        ? "center center"
        : "",
    backgroundRepeat:
      theme.overlayBackgroundImage && theme.overlayBackgroundImageUrl
        ? "no-repeat"
        : "",
    backgroundSize:
      theme.overlayBackgroundImage && theme.overlayBackgroundImageUrl
        ? "cover"
        : "",
    backgroundAttachment: "fixed",
  };

  function getData(selectedDate) {
    dispatch(incrementLoader());
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    let eventsArray = [];
    let openingHours = [];
    let obj = {
      outlet_id: oid,
      interval: 15,
    };
    if (selectedDate) {
      obj.date = selectedDate;
    }
    getWidgetBootstrap(obj).then((response) => {
      if (response.message === "Invalid Credentials") {
        auth().then((response) => {
          localStorage.removeItem("access_token");
          dispatch(
            updateStateVariable({
              key: "accessToken",
              value: response,
            })
          );
          window.location.reload();
          return "";
        });
      }
      if (response.message == "No shifts available for this outlet" || response.message === 'We are no longer accepting online reservations. Thank you for your visit.') {
        setErrorMessage(t("we_are_no_longer_accepting_online_reservations_Thank_you_for_your_visit"));
      } else {
        if (response.result.venue_details) {
          dispatch(
            updateStateVariable({
              key: "venueDetails",
              value: response.result.venue_details,
            })
          );
        }
        dispatch(
          updateStateVariable({
            key: "allowedDays",
            value: response.result.allowed_days,
          })
        );
        dispatch(
          updateStateVariable({
            key: "allAllowedDays",
            value: response.result.allowed_days,
          })
        );
        dispatch(
          updateStateVariable({
            key: "highlightedEvents",
            value: response.result.highlighted_events,
          })
        );
        dispatch(
          updateStateVariable({
            key: "countries",
            value: response.result.countries,
          })
        );
        dispatch(
          updateStateVariable({
            key: "currency",
            value: response.result.currency.code,
          })
        );
        dispatch(
          updateProperty({
            key: "date",
            value: selectedDate ? selectedDate : response.result.allowed_days[0],
          })
        );
        dispatch(
          updateStateVariable({
            key: "preferredTables",
            value: response.result.sections,
          })
        );
        dispatch(
          updateStateVariable({
            key: "preferredSections",
            value: response.result.sections,
          })
        );
        dispatch(
          updateStateVariable({
            key: "widgetTags",
            value: response.result.tags.widget_tags,
          })
        );
        dispatch(
          updateStateVariable({
            key: "allergiesTags",
            value: response.result.tags.allergies,
          })
        );
        dispatch(
          updateStateVariable({
            key: "dietaryTags",
            value: response.result.tags.dietary_restriction,
          })
        );
        dispatch(
          updateStateVariable({
            key: "occasionsTags",
            value: response.result.tags.occasions,
          })
        );
        var metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
          metaTag.setAttribute("content", response.result.venue_details.outlet_name);
        }
        let path = location.pathname.split("/event/");
        if (path.length > 1) {
          // EVENT DETAILS PAGE, GET SHIFT BY ID THEN GET SHIFTS BY DATE RANGE OF THE SHIFT
          let shiftID = path[1].split("/")[0];
          getSpecialShift(shiftID);
        } else if (path.length === 1 && path[0] === "/event") {
          dispatch(incrementLoader());
          // EVENT LIST PAGE, GET SHIFT BY ALLOWED DAYS FROM WIDGET BOOTSTRAP API
          let obj = {
            outlet_id: oid,
            list_type: "interval",
            date_from: response.result.allowed_days[0],
            date_to:
              response.result.allowed_days[
              response.result.allowed_days.length - 1
              ],
          };
          getShifts(obj).then((res) => {
            dispatch(decrementLoader());
          });
        } else {
          // response.result.allowed_days.forEach(day=>{
          getShiftsByDay(response.result.allowed_days);
          // })
        }
      }
      dispatch(decrementLoader());
    });
  }

  function formatShiftIntervals(shifs){
    let available_times = [];

    shifs.forEach(shif => {
      let sh = JSON.parse(JSON.stringify(shif));
      if (!sh.online_active_intervals.length)
        valid++;
      else {
        //adding shift id to available time
        sh.online_active_intervals.forEach((item, index) => {
          sh.online_active_intervals[index].shift_id = sh.shift_id;
          sh.online_active_intervals[index].shift_max_cover=sh.max_guest_count;
          sh.online_active_intervals[index].allow_waitlist = sh.allow_waitlist;
        })
        available_times = [...available_times, ...sh.online_active_intervals]
        dispatch(
          updateStateVariable({
            key: "allShiftsAvailableTimes",
            value: available_times,
          })
        );
        dispatch(
          updateStateVariable({
            key: "shiftsAvailableTimes",
            value: available_times,
          })
        );
        return available_times;
      }
    })
  }

  function getShiftsByDay(days, event_id = '') {
    if (days.length) {
      let day = days[0];
      let valid = 0;
      getShift(day, event_id).then((openingHour) => {
        let openingHours=JSON.parse(JSON.stringify(openingHour));
        if (event_id === '') {
          formatShiftIntervals(openingHours);
        }
        if (valid === openingHours.length) {
          let editedDays = JSON.parse(JSON.stringify(days));
          editedDays.splice(0, 1);
          getShiftsByDay(editedDays);
        } else {
          dispatch(
            updateProperty({
              key: "date",
              value: day,
            })
          );
        }
      });
    } else {
      setErrorMessage('widgetAddReservationFailure');
    }
  }

  function getSpecialShift(id) {
    dispatch(incrementLoader());
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    let obj = {
      outlet_id: oid,
      id: id,
    };
    getEventById(obj).then((shiftIdResponse) => {
      dispatch(
        updateStateVariable({
          key: "allowedDays",
          value: shiftIdResponse.allowed_days,
        })
      );
      dispatch(
        updateStateVariable({
          key: "eventDetails",
          value: shiftIdResponse
        })
      );
      getEventTimeslots(id, shiftIdResponse, 0, oid)
    });
  }

  function getEventTimeslots(id, eventDetail, allowedDaysInterval, oid) {
    dispatch(incrementLoader());
    let eventDetails = JSON.parse(JSON.stringify(eventDetail));
    dispatch(updateProperty({ key: "time", value: { time: "" } }));
    dispatch(updateProperty({ key: "date", value: eventDetails.allowed_days[allowedDaysInterval] }));

    getEventTimeslotsByDate({ outlet_id: oid, id: id, date: eventDetails.allowed_days[allowedDaysInterval] }).then((timeSlotsResponse) => {
      dispatch(decrementLoader());
      if ((!timeSlotsResponse.online_active_intervals || !timeSlotsResponse.online_active_intervals.length) && eventDetails.allowed_days[allowedDaysInterval+1]) {
        getEventTimeslots(id, eventDetails, allowedDaysInterval+1,oid)
      }
      else {
        //adding shift id to available time
        let eventIntervals = JSON.parse(JSON.stringify(timeSlotsResponse.online_active_intervals));
        eventDetails.online_active_intervals = eventIntervals;

        //adding shift id to available time
        eventIntervals.forEach((item, index) => {
          eventIntervals[index].event_id = timeSlotsResponse.event_id;
          eventIntervals[index].shift_max_cover = eventDetails.max_guest_count;
        })
        dispatch(
          updateStateVariable({
            key: "allShiftsAvailableTimes",
            value: eventIntervals,
          })
        );
        dispatch(
          updateStateVariable({
            key: "shiftsAvailableTimes",
            value: eventIntervals,
          })
        );
        dispatch(
          updateStateVariable({ key: "selectedEvent", value: eventDetails })
        );
        dispatch(updateProperty({ key: "section", value: eventDetails }));
      }
    })
  }

  function fetchData() {
    if (!previewMode) {
      getData();
    } else {
      window.addEventListener("message", function (e) {
        if(e.data.query_string){
          dispatch(
            updateStateVariable({
              key: "theme",
              value: {...theme, ...e.data.query_string},
            })
          );
        }
      });
      getDummyData().then((response) => {
        dispatch(
          updateStateVariable({
            key: "allowedDays",
            value: response.allowed_days,
          })
        );
        dispatch(
          updateStateVariable({
            key: "preferredTables",
            value: response.sections,
          })
        );
        dispatch(
          updateStateVariable({
            key: "preferredSections",
            value: response.sections,
          })
        );
        dispatch(
          updateStateVariable({
            key: "venuesList",
            value: response.venues,
          })
        );
        dispatch(
          updateStateVariable({
            key: "multipleVenues",
            value: response.multiple_venues,
          })
        );
        dispatch(
          updateProperty({ key: "date", value: response.date })
        );
        dispatch(
          updateStateVariable({
            key: "sections",
            value: response.opening_hours_by_shift,
          })
        );
        dispatch(
          updateStateVariable({
            key: "availableTimes",
            value: response.opening_hours_by_shift[0].available_time,
          })
        );
        let defaultShift=JSON.parse(JSON.stringify(response.opening_hours_by_shift[0]));
        defaultShift.selectedTime={
          start_time:"2:00",
          capacity:100
        }
        dispatch(updateProperty({ key: "section", value: defaultShift }));
        dispatch(updateStateVariable({ key: "allShiftsAvailableTimes", value: response.allShiftsAvailableTimes }));
        dispatch(updateStateVariable({ key: "shiftsAvailableTimes", value: response.allShiftsAvailableTimes }));
        dispatch(updateStateVariable({ key: "selectedEvent", value: response.selectedEvent }));

        dispatch(updateStateVariable({ key: "currency", value: response.currency }));
        dispatch(
          updateStateVariable({
            key: "allergiesTags",
            value: response.opening_hours_by_shift[0].tags.allergies,
          })
        );
        dispatch(
          updateStateVariable({
            key: "dietaryTags",
            value: response.opening_hours_by_shift[0].tags.dietary,
          })
        );
        dispatch(
          updateStateVariable({
            key: "occasionsTags",
            value: response.opening_hours_by_shift[0].tags.occasion,
          })
        );
        dispatch(updateProperty({ key: "tableFor", value: response.tableFor }));
        dispatch(updateProperty({ key: "time", value: response.time }));
        dispatch(updateProperty({ key: "totalAmount", value: response.totalAmount }));
        dispatch(updateProperty({ key: "referenceCode", value: response.referenceCode }));
        dispatch(updateNote({ key: "widget", value: response.widget }));
        dispatch(updateTagNote({ key: "allergies", value: response.allergies }));
        dispatch(updateTagNote({ key: "dietary", value: response.dietary }));
        dispatch(updateTagNote({ key: "occasion", value: response.occasion }));
        dispatch(decrementLoader());
      });
    }
  }

  function getShift(selectedDate, event_id = '',loadertoggle=true) {
    if(loadertoggle)
      dispatch(incrementLoader());
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    let previewMode = query.get("preview");
    let obj = {
      outlet_id: oid,
      list_type: "interval",
      date_from: selectedDate,
      date_to: selectedDate,
    };
    if (event_id !== '')
      obj.event_id = event_id;
    if (!parseInt(previewMode)) {
      if (event_id) {
        let eventDetail = JSON.parse(JSON.stringify(eventDetails));
        dispatch(updateProperty({ key: "time", value: { time: "" } }));
        dispatch(updateProperty({ key: "date", value: selectedDate }));
        return getEventTimeslotsByDate({ outlet_id: oid, id: event_id, date: selectedDate }).then((timeSlotsResponse) => {
          // if (!timeSlotsResponse.online_active_intervals && !timeSlotsResponse.online_active_intervals.length) {
          //   getEventTimeslots(id, eventDetail, allowedDaysInterval++)
          // }
          // else {
            //adding shift id to available time
            let eventIntervals = JSON.parse(JSON.stringify(timeSlotsResponse.online_active_intervals));
            if(eventIntervals && eventIntervals.length){

              let sorted_online_start_intervals=eventIntervals.sort((a, b) =>{
             
               let start_time = dateFormat(moment().format('YYYY-MM-DD') + "T" + a.start_time, "", "YYYY-MM-DD HH:mm A");
               let end_time = dateFormat(moment().format('YYYY-MM-DD') + "T" + b.start_time, "", "YYYY-MM-DD HH:mm A");
             let startTimeToCheck=moment(moment().format('YYYY-MM-DD')+'T'+'00:00').format('YYYY-MM-DD HH:mm A');
             let endTimeToCheck=moment(moment().format('YYYY-MM-DD')+'T'+'06:00').format('YYYY-MM-DD HH:mm A');
             if(start_time>=startTimeToCheck && start_time <=endTimeToCheck)
             {
               let st=moment(start_time).add(1, "days");
               start_time=st.format('YYYY-MM-DD HH:mm A');
             }
             if(end_time>=startTimeToCheck && end_time <endTimeToCheck)
             {
               let st=moment(end_time).add(1, "days");
               end_time=st.format('YYYY-MM-DD HH:mm A');
             }
               return start_time > end_time
                 ? 1
                 : -1
             })
             eventIntervals=sorted_online_start_intervals;
            }
            // let available_times=[];
            //adding shift id to available time
            eventIntervals.forEach((item, index) => {
              eventIntervals[index].event_id = timeSlotsResponse.event_id;
              eventIntervals[index].shift_max_cover = eventDetail.max_guest_count;
            })


            dispatch(
              updateStateVariable({
                key: "allShiftsAvailableTimes",
                value: eventIntervals,
              })
            );
            eventDetail.online_active_intervals = eventIntervals;
            dispatch(
              updateStateVariable({ key: "selectedEvent", value: eventDetail })
            );
            if(loadertoggle)
              dispatch(decrementLoader());
          return '';
        });
      } else {
        return getShifts(obj).then((response) => {
          let shifts = response;
          let filtered_shifts = [];
          if (response.length) {
            response.forEach((shift) => {
              shift.available_time = shift.online_active_intervals;
            });
            shifts = shifts.filter((shift) => {
              return shift.available_time.length > 0;
            });
            if (shifts.length)
              filtered_shifts = shifts.sort((a, b) =>
                moment(a.start_datetime) > moment(b.start_datetime)
                  ? 1
                  : -1
              );
            
            let countsOfDisabled = 0;
            let available_times=[];
            filtered_shifts.forEach((shift, index) => {
              let sh = JSON.parse(JSON.stringify(shift));
                //adding shift id to available time
                sh.online_active_intervals.forEach((item, index) => {
                  sh.online_active_intervals[index].shift_id = sh.shift_id;
                  sh.online_active_intervals[index].shift_max_cover=sh.max_guest_count;
                  sh.online_active_intervals[index].allow_waitlist = sh.allow_waitlist;
                })
                available_times = [...available_times, ...sh.online_active_intervals]
              let shiftDisabledResult = shift.available_time.filter((item) => {
                return item.capacity < 1;
              });
              if (shiftDisabledResult.length === shift.available_time.length) {
                shift.disabled = true;
                countsOfDisabled++;
              }
            });
            
            dispatch(
              updateStateVariable({
                key: "allShiftsAvailableTimes",
                value: available_times,
              })
            );

            dispatch(
              updateStateVariable({
                key: "shiftsAvailableTimes",
                value: available_times,
              })
            );

            if (countsOfDisabled === filtered_shifts.length) {
              dispatch(
                updateProperty({
                  key: "allShiftsDisabled",
                  value: true,
                })
              );
            } else {
              dispatch(
                updateProperty({
                  key: "allShiftsDisabled",
                  value: false,
                })
              );
            }
          } else {
            dispatch(
              updateProperty({
                key: "allShiftsDisabled",
                value: false,
              })
            );
          }
          dispatch(
            updateStateVariable({
              key: "sections",
              value: filtered_shifts,
            })
          );

          let selSection = {
            name: '',
            shift_id: '',
            shift_metadata_name: '',
            allow_more_than_max_covers: 0,
            min_guest_count: 0,
            max_guest_count: 0
          };

          dispatch(
            updateProperty({
              key: "section",
              value: selSection,
            })
          );


          if (
            filtered_shifts.length &&
            filtered_shifts[0].category === "special"
          ) {
            dispatch(
              updateProperty({
                key: "selectedEvent",
                value: filtered_shifts[0],
              })
            );
          }
          if(loadertoggle)
            dispatch(decrementLoader());
          return filtered_shifts;
        });
      }
    }
  }

  function dateFormat(dateTime, tz, format) {

    let dateFromTime = moment(dateTime).format("YYYY-MM-DD");
    let time = moment(dateTime).format("HH:mm");
    let offset = moment.tz
      .zone(venueDetails.timezone)
      .utcOffset(moment(dateFromTime));
    return moment(dateFromTime + "T" + time)
      .add(-offset, "minutes")
      .format(format);
  }
  function getSettings() {
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    let previewMode = query.get("preview");
    let obj = {
      outlet_id: oid,
    };
    if (!parseInt(previewMode)) {
      getVenueSettings(obj).then((response) => {
        if (response) {
          let result = dataFormatter.deserialize(response);
          dispatch(
            updateStateVariable({ key: "settingsFormated", value: result })
          );
          dispatch(
            updateStateVariable({
              key: "settingsLoaded",
              value: true,
            })
          );
          dispatch(themeHandler(result));
        }
      });
    }
  }

  function timerFunction() {
    if (timerToggle) {
      return <Timer hoursMinSecs={time} />;
    }
  }

  function previewLineToggle() {
    if (previewMode) {
      return <PreviewLine />;
    } else {
      return "";
    }
  }
  useEffect(() => {
    if (location.hash.includes("#/widget/events")) {
      let hashes = location.hash.split("/");
      history.push({
        pathname: "/event/" + hashes[hashes.length - 1],
        search: window.location.search,
      });

      history.go(0);
    }
    if (theme.previewPage) {
      history.push({
        pathname: previewLineLinks[theme.previewPage - 1],
        search: window.location.search,
      });
    }
    dispatch(incrementLoader());
    auth().then((response) => {
      dispatch(
        updateStateVariable({
          key: "accessToken",
          value: response.access_token,
        })
      );
      getSettings();
      dispatch(decrementLoader());
      fetchData();
    });
  }, []);
  function timeFormater(time) {
    let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date));
    let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
    // if(is_dst){
    //   offset+=60;
    // }
    return moment(reservation.date + "T" + time)
      .add(-offset, "minutes")
      .format("HH:mm");
  }
  return (
    <div className="App">
      <Loader /> {previewLineToggle()}{" "}
      <LayoutContainer
        alignItems="center"
        justifyContent="center"
        style={appDesgin}
        className="appRoot"
      >
        <LayoutItem
          xs={12}
          className="BackgroundOverlay"
          style={
            theme.overlayBackgroundColor
              ? { backgroundColor: theme.overlayBackgroundColor }
              : {}
          }
        >
          <LayoutItem xs={12} className="appContainer widgetHeader">
            {" "}
            {/* <div className="timerCont">{timerFunction()}</div> */}{" "}
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Make errorMessage={errorMessage} getDataFunc={getShift} />}
              // render={() => <ViewEventDetails />}
              // render={(props) => <EventList getDataFunc={getShift} />}

              />
              <Route exact path="/preview/:page" component={Preview} />{" "}
              <Route exact path="/login" component={Login} />{" "}
              <Route exact path="/payment" component={Payment} />{" "}
              <Route exact path="/payment-package" component={PaymentPackage} />
              <Route exact path="/event" component={ViewEventDetails} />
              <Route
                exact
                path="/event"
                render={(props) => <EventList getDataFunc={getShift} />}
              />
              <Route
                exact
                path="/event/:id"
                render={(props) => <ViewEventDetails errorMessage={errorMessage} getDataFunc={getShift} />}
              />

              <Route exact path="/event/:id/payment-package" component={PaymentPackage} />
              <Route
                exact
                path="/success"
                render={() => (
                  <Success
                    title={reservation.reservation_type === 'normal' ? (theme.widgetAddReservationSuccess && defaultLang==='en' ? theme.widgetAddReservationSuccess : t('thank_you_for_making_a_reservation_with_us_we_are_thrilled_to_have_the_opportunity_to_host_you_and_ensure_that_your_visit_is_a_memorable_one_please_do_not_hesitate_to_let_us_know_if_there_is_anything_we_can_do_to_make_your_visit_more_enjoyable')) : theme.widgetAddReservationAboveLimit && defaultLang==='en' ? theme.widgetAddReservationAboveLimit : t('dear_guest_your_request_was_received_we_will_call_you_to_confirm_your_reservation')}
                    authed={true}
                    buttonText={t("make_another_reservation")}
                    buttonText2={t("check_out_our_website")}
                  />
                )}
              />{" "}
              <Route
                exact
                path="/failed"
                render={() => (
                  <Failed
                    title={
                      theme.widgetAddReservationFailure && defaultLang==='en'
                        ? theme.widgetAddReservationFailure
                        : t("dear_guest_something_went_wrong_with_your_reservation_please_try_again")
                    }
                    authed={true}
                    buttonText={t("make_another_reservation")}
                    buttonText1={t("check_out_our_website")}
                    buttonText2={t("try_again")}
                  />
                )}
              />{" "}
              <Route
                exact
                path="/payment-success"
                render={() => (
                  <PaymentSuccess
                    title={
                      theme.widgetAddReservationSuccess && defaultLang==='en'
                        ? theme.widgetAddReservationSuccess
                        : t('thank_you_for_making_a_reservation_with_us_we_are_thrilled_to_have_the_opportunity_to_host_you_and_ensure_that_your_visit_is_a_memorable_one_please_do_not_hesitate_to_let_us_know_if_there_is_anything_we_can_do_to_make_your_visit_more_enjoyable')
                    }
                    authed={true}
                    buttonText={t("make_more_reservation")}
                    buttonText2={t("check_out_our_website")}
                  />
                )}
              />
              <Route
                exact
                path="/payment-failed"
                render={() => (
                  <PaymentFailed
                    title={
                      theme.widgetAddReservationFailure && defaultLang==='en'
                        ? theme.widgetAddReservationFailure
                        : t("dear_guest_something_went_wrong_with_your_reservation_please_try_again")
                    }
                    authed={true}
                    buttonText={t("make_another_reservation")}
                    buttonText1={t("check_out_our_website")}
                    buttonText2={t("try_again")}
                  />
                )}
              />
              <Route exact path="/reservation/:id" component={Cancel} /> 
            </Switch>{" "}
          </LayoutItem>{" "}
        </LayoutItem>{" "}
      </LayoutContainer>{" "}
    </div>
  );
}

export default App;
