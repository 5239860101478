import * as React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import TagsField from "./TagsField";
import TextareaEditor from "./TextareaEditor";
import TagsFieldSelect from "./TagsFieldSelect";

export default function FeedbackForm(props) {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.store.theme);

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ""
  };

  const handleChange = (key, value) => {
    if (props.onChangeValue) { props.onChangeValue(key, value) };
  }

  const handleChangeNote = (e) => {
    if (props.onChangeNote) { props.onChangeNote('widget', e.target.value) };
  }

  function allergiesShow() {
    if (theme['allowAllergiesSelection'] == 1) {
      return (<LayoutItem xs={12}><TagsFieldSelect name='allergies' label={t("allergies")} variant="outlined" onChangeNote={handleChange} /></LayoutItem>)
    }
  }

  function dietaryShow() {
    if (theme['allowDietaryPreference'] == 1) {
      return (<LayoutItem xs={12}><TagsFieldSelect name='dietary' label={t("any_dietary_preferences")} variant="outlined" onChangeNote={handleChange} /></LayoutItem>)
    }
  }

  function occasionShow() {
    if (theme['allowSpecialOccasion'] == 1) {
      return (<LayoutItem xs={12}><TagsFieldSelect name='occasion' label={t("a_special_occasion")} variant="outlined" onChangeNote={handleChange} /></LayoutItem>)
    }
  }

  const useStyles = makeStyles({
    root: {
      background: 'white',
      borderRadius: 5,
      "& textarea": {
        fontSize: '0.85rem',
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ""
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: theme.calendarTextColor ? theme.calendarTextColor : ""
      }
    }
  });

  const classes = useStyles();

  return (
    <LayoutContainer
      spacing={2}
      alignItems="center"
      justifyContent="center"
      className="sectionTable allergiesForm"
    >
      <LayoutItem xs={12}>
        <Typography
          variant="h5"
          display="block"
          align="left"
          className="sectionTitle"
        >
          <div
            style={colorDesign}>
            {t("please_let_us_know_if_you_have")}
          </div>
        </Typography>
      </LayoutItem>
      {allergiesShow()}
      {dietaryShow()}
      {occasionShow()}
      <LayoutItem xs={12}>
        <TextField
          multiline
          rows={3}
          name='widget'
          onChange={handleChangeNote}
          fullWidth
          className={classes.root}
          placeholder={t("or_specify_any_additional_requests")}
          variant="outlined"
        />
      </LayoutItem>
    </LayoutContainer >
  );
}