import * as React from "react";
import { useHistory } from "react-router-dom";
import Create from "./../reservation/Create";
import Payment from "./../Payment";

export default function Preview(props) {
  const history = useHistory();
  function loadComponent() {
    if (props.match.params.page === "create") {
      return <Create />;
    } else if (props.match.params.page === "payment") {
      return <Payment />;
    }
  }
  
  return (
  <div className="preview">
      {loadComponent()}
      </div>
      );
}
