import * as React from "react";
import Carousel from "nuka-carousel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import "./../../assets/scss/Event.scss";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import { reservation } from "../../features/store/storeSlice";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import defaultImage from '../../assets/default.jpg'
import {
  updateProperty,
  updateStateVariable,
  decrementLoader,
  incrementLoader,
} from "./../../features/store/storeSlice";
import { getShiftById,getShifts } from "./../../features/store/api";
import { RestoreOutlined } from "@material-ui/icons";

export default function EventCarousel(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { innerWidth: width, innerHeight: height } = window;
  const { theme, sections, reservation } = useSelector((state) => state.store);
  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };
  function openEvent(event,sects) {
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    window.location.replace("/event/" + event.event_id+"?oid="+oid);
    history.push({
      pathname: "/event/" + event.event_id,
      search: window.location.search,
    });
    // getSpecialShift(event.shift_id);
    // let dates = [];
    // // if(!event.id){
    // //   event=choosenEvent;
    // // }
    // let sectionss=sects?sects:sections;
    // if (event.allowed_days) dates = Object.values(event.allowed_days);
    // let result=[];
    // result = sectionss.filter((item) => {
    //   return parseInt(item.shift_id) === parseInt(event.shift_id);
    // });
    // if (result.length) {
    //   dispatch(updateProperty({ key: "section", value: result[0] }));
    //   dispatch(updateProperty({ key: "selectedEvent", value: result[0] }));
    //   dispatch(updateProperty({ key: "date", value: dates[0] }));
    //   history.push({
    //     pathname: "/event/" + event.shift_id,
    //     search: window.location.search,
    //   });
    // } else {
    //   if (dates.length && reservation.date)
    //     onChangeDate(
    //       dates,
    //       event.shift_id
    //     );
    // }
  }



  function getSpecialShift(shiftId) {
    dispatch(incrementLoader());
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    let obj = {
      outlet_id: oid,
      shift_id: shiftId,
    };
    getShiftById(obj).then((shiftIdResponse) => {
      if (shiftIdResponse.length && shiftIdResponse[0].allowed_days.length) {
        let obj = {
          outlet_id: oid,
          list_type: "interval",
          date_from: shiftIdResponse[0].allowed_days[0],
          date_to:
            shiftIdResponse[0].allowed_days[
              shiftIdResponse[0].allowed_days.length - 1
            ],
        };
        getShifts(obj).then((shifts) => {
          let result = shifts.filter((shift) => {
            return shift.shift_id === shiftId;
          });
          if (result.length) {
            let selectedShift = result[0];
            let availableTime = selectedShift.intervals.filter((interval) => {
              return interval.date === selectedShift.allowed_days[0];
            });
            selectedShift.available_time =
              availableTime[0].online_active_intervals;
            dispatch(
              updateProperty({ key: "selectedEvent", value: selectedShift })
            );
            dispatch(updateProperty({ key: "section", value: selectedShift }));
            dispatch(updateProperty({ key: "date", value: selectedShift.allowed_days[0] }));
                  history.push({
        pathname: "/event/" + selectedShift.shift_id,
        search: window.location.search,
      });
          }
          dispatch(decrementLoader());
        });
      }
    });
  }

  async function onChangeDate(dates, id) {
    let brek;
    for(let value of dates){
      let date = moment(value).format("YYYY-MM-DD");
      dispatch(updateProperty({ key: "date", value: date }));
      await props.getDataFunc (date).then((respon)=>{
        let result = respon.filter((item) => {
          return parseInt(item.shift_id) === parseInt(id);
        });
        if(result.length){
        openEvent(result[0],respon);
        throw brek;
      }
    });
    }
  }

  return (
    <LayoutContainer
      spacing={1}
      alignItems="center"
      justifyContent="center"
      className="sectionTable"
    >
      <LayoutItem xs={12} className="eventCarouselTitle" style={colorDesign}>
        
      <Typography
                variant="h5"
                display="block"
                align="center"
                className="sectionTitle SelectedMonth"
              >
                <div style={colorDesign}>
                  {t('other_events_available')}
                </div>
              </Typography>
            </LayoutItem>
      <LayoutItem xs={12} className="eventCarouselContainer eventCarouselContainerBottom eventPostSlider">
        <Carousel
          slidesToShow={width < 500 ? 2 : 3}
          renderBottomCenterControls={() => {
            return false;
          }}
          cellSpacing={10}
          renderCenterLeftControls={({ previousSlide }) => (
            <ChevronLeftIcon
              className="carouselRenderButtons"
              onClick={previousSlide}
            />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <ChevronRightIcon
              className="carouselRenderButtons"
              onClick={nextSlide}
            />
          )}
        >
          {props.data.map((value, index) => {
            return (
              <div
                className="event"
                key={index}
                onClick={(e) => openEvent(value)}
              >
                <div className="eventCarouselImage" style={{backgroundImage:`url(${value.event_banner_image && value.event_banner_image.length?value.event_banner_image[0]:defaultImage})`}}>
                  {/* <img src={value.event_banner_image && value.event_banner_image.length?value.event_banner_image[0]:''} /> */}
                </div>
                <div className="eventCarouselDetails">
                  <div className="eventName" style={colorDesign}>
                    {value.event_metadata_name}
                  </div>
                  {/* <div className="eventDate" style={colorDesign}>
                    {t("from") +
                      " " +
                      moment(value.start_datetime).format("ddd DD MMMM YYYY") + 
                      " " +
                      t("to") +
                      " " +
                      moment(value.end_datetime).format("ddd DD MMMM YYYY")}
                  </div> */}
                  {/* <div className="eventTime" style={colorDesign}>{t('from')} {moment(value.timeFrom).format('LT')} {t('till')} {moment(value.timeTo).format('LT')}</div> */}
                </div>
              </div>
            );
          })}
        </Carousel>
      </LayoutItem>
    </LayoutContainer>
  );
}
