import { RemoveShoppingCartOutlined } from '@material-ui/icons';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCount } from './counterAPI';

const queryParams = new URLSearchParams(window.location.search);

const toCamelCase = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('_', '');
    });
}
const initialState = {
    value: 0,
    userData: {
        firstName: 'Karl',
        lastName: 'Atiyeh',
        phoneNumber: '70101272',
        emailAddress: 'husseinamhaz101@gmail.com',
        newsletter: true
    },
    venueDetails: { outlet_name: '', timezone: "Asia/Dubai", country: "AE" },
    reservation: {
        tableFor: 0,
        date: null,
        time: {
            time: ''
        },
        section: {
            name: '',
            shift_id: '',
            shift_metadata_name: '',
            allow_more_than_max_covers: 0,
            min_guest_count: 0,
            max_guest_count: 0
        },
        eventSection: {
            name: ''
        },
        selectedEvent: {},
        allShiftsDisabled: false,
        newsletter: false,
        selectedCategories: [],
        preferredTable: 'Any section',
        country_name: '',
        country_id: 0,
        country_isd: 0,
        totalAmount: 0,
        section_id: 0,
        reservation_type: 'normal',
        referenceCode: '',
        duration: 0
    },
    orders: [{
        name: "Board Game Night At Multiverse",
        date: "Every Tuesday to Sunday from 02:00 pm until 11:00 pm, Aug 17 2021 to Dec 30 2021",
        dateFrom: "2021-08-17",
        dateTo: "2021-12-30",
        price: 250,
        currency: "AED",
        seats: 2
    }],
    currency: "",
    timer: { hours: 0, minutes: 10, seconds: 0 },
    widgetTags: [],
    highlightedEvents: [],
    allergiesTags: [],
    dietaryTags: [],
    occasionsTags: [],
    tagsIds: {
        widget: [],
        allergies: [],
        dietary: [],
        occasion: []
    },
    preferredTables: [],
    preferredSections: [],
    availableTimes: [],
    selectedTags: {
        widget: [],
        allergies: [],
        dietary: [],
        occasion: []
    },
    theme: {
        overlayOpacity: queryParams.get('overlayOpacity') ? queryParams.get('overlayOpacity') : '',
        widgetBordersRadius: queryParams.get('widgetBordersRadius') ? queryParams.get('widgetBordersRadius') + 'px' : '',
        color: queryParams.get('calendarTextColor') ? '#' + queryParams.get('calendarTextColor') : '',
        defaultBoxColor: queryParams.get('defaultBoxColor') ? '#' + queryParams.get('defaultBoxColor') : '',
        defaultBoxOpacity: queryParams.get('defaultBoxOpacity') ? queryParams.get('defaultBoxOpacity') : '',
        selectedColor: queryParams.get('selectedColor') ? '#' + queryParams.get('selectedColor') : '',
        selectedColorOpacity: queryParams.get('selectedColorOpacity') ? queryParams.get('selectedColorOpacity') : '',
        textColorOnSelectedArea: queryParams.get('textColorOnSelectedArea') ? '#' + queryParams.get('textColorOnSelectedArea') : '',
        widgetLanguage: queryParams.get('widgetLanguage') ? queryParams.get('widgetLanguage') : '',
        showTimeslotsSections: queryParams.get('showTimeslotsSections') ? queryParams.get('showTimeslotsSections') : '',
        allowTablePreferenceSelection: queryParams.get('allowTablePreferenceSelection') ? queryParams.get('allowTablePreferenceSelection') : '',
        allowFloorplanTableSelection: queryParams.get('allowFloorplanTableSelection') ? queryParams.get('allowFloorplanTableSelection') : '',
        allowAllergiesSelection: queryParams.get('allowAllergiesSelection') ? queryParams.get('allowAllergiesSelection') : '',
        allowDietaryPreference: queryParams.get('allowDietaryPreference') ? queryParams.get('allowDietaryPreference') : '',
        allowSpecialOccasion: queryParams.get('allowSpecialOccasion') ? queryParams.get('allowSpecialOccasion') : '',
        requestBirthdayInput: queryParams.get('requestBirthdayInput') ? queryParams.get('requestBirthdayInput') : '',
        socialMediaLogin: queryParams.get('socialMediaLogin') ? queryParams.get('socialMediaLogin') : '',
        showHideTimeslots: queryParams.get('showHideTimeslots') ? queryParams.get('showHideTimeslots') : 0,
        allowReservationConfirmation: queryParams.get('allowReservationConfirmation') ? queryParams.get('allowReservationConfirmation') : '',
        allowCustomThankYouPage: queryParams.get('allowCustomThankYouPage') ? queryParams.get('allowCustomThankYouPage') : '',
        customThankYouPageUrl: queryParams.get('customThankYouPageUrl') ? queryParams.get('customThankYouPageUrl') : '',
        digitalMenu: queryParams.get('digitalMenu') ? queryParams.get('digitalMenu') : '',
        coverImage: queryParams.get('coverImage') ? queryParams.get('coverImage') : '',
        coverImageUrl: queryParams.get('coverImageUrl') ? queryParams.get('coverImageUrl') : '',
        logo: queryParams.get('logo') ? queryParams.get('logo') : '',
        logoUrl: queryParams.get('logoUrl') ? queryParams.get('logoUrl') : '',
        headerTitle: queryParams.get('headerTitle') ? queryParams.get('headerTitle') : '',
        headerTitleValue: queryParams.get('headerTitleValue') ? queryParams.get('headerTitleValue') : '',
        overlayBackgroundImage: queryParams.get('overlayBackgroundImage') ? queryParams.get('overlayBackgroundImageUrl') : '',
        overlayBackgroundImageUrl: queryParams.get('overlayBackgroundImageUrl') ? queryParams.get('overlayBackgroundImageUrl') : '',
        overlayBackgroundColor: queryParams.get('overlayBackgroundColor') ? queryParams.get('overlayBackgroundColor') : '',
        overlayBackgroundColorOpacity: queryParams.get('overlayBackgroundColorOpacity') ? queryParams.get('overlayBackgroundColorOpacity') : '',
        widgetBackgroundColor: queryParams.get('widgetBackgroundColor') ? queryParams.get('widgetBackgroundColor') : '',
        widgetBorderRadius: queryParams.get('widgetBorderRadius') ? queryParams.get('widgetBorderRadius') : '',
        calendarFontFamily: queryParams.get('calendarFontFamily') ? queryParams.get('calendarFontFamily') : '',
        calendarTextColor: queryParams.get('calendarTextColor') ? queryParams.get('calendarTextColor') : '',
        calendarLinkColor: queryParams.get('calendarLinkColor') ? queryParams.get('calendarLinkColor') : '',
        calendarBoxColor: queryParams.get('calendarBoxColor') ? queryParams.get('calendarBoxColor') : '',
        calendarBoxOpacity: queryParams.get('calendarBoxOpacity') ? queryParams.get('calendarBoxOpacity') : '',
        calendarSelectedColor: queryParams.get('calendarSelectedColor') ? queryParams.get('calendarSelectedColor') : '',
        calendarSelectedOpacity: queryParams.get('calendarSelectedOpacity') ? queryParams.get('calendarSelectedOpacity') : '',
        calendarSelectedAreaColor: queryParams.get('calendarSelectedAreaColor') ? queryParams.get('calendarSelectedAreaColor') : '',
        buttonBackgroundColor: queryParams.get('buttonBackgroundColor') ? queryParams.get('buttonBackgroundColor') : '',
        buttonTextColor: queryParams.get('buttonTextColor') ? queryParams.get('buttonTextColor') : '',
        timerBackgroundColor: queryParams.get('timerBackgroundColor') ? queryParams.get('timerBackgroundColor') : '',
        timerTextColor: queryParams.get('timerTextColor') ? queryParams.get('timerTextColor') : '',
        eventCarouselPosition: queryParams.get('eventCarouselPosition') ? queryParams.get('eventCarouselPosition') : '',
        fontFamily: queryParams.get('calendarFontFamily') ? queryParams.get('calendarFontFamily') : '',
        calendarView: queryParams.get('calendarView') ? queryParams.get('calendarView') : '',
        tableFor: queryParams.get('tableFor') ? queryParams.get('tableFor') : '',
        iWantToHave: queryParams.get('iWantToHave') ? queryParams.get('iWantToHave') : '',
        availableTime: queryParams.get('availableTime') ? queryParams.get('availableTime') : '',
        paymentFailed: queryParams.get('paymentFailed') ? queryParams.get('paymentFailed') : '',
        thisDueTo: queryParams.get('thisDueTo') ? queryParams.get('thisDueTo') : '',
        makeMoreReservations: queryParams.get('makeMoreReservations') ? queryParams.get('makeMoreReservations') : '',
        tryAgain: queryParams.get('tryAgain') ? queryParams.get('tryAgain') : '',
        multipleVenues: queryParams.get('multipleVenues') ? queryParams.get('multipleVenues') : '',
        venuesList: queryParams.get('venuesList') ? queryParams.get('venuesList') : '',
        ppPreferredName: queryParams.get('ppPreferredName') ? queryParams.get('ppPreferredName') : '',
        ppLink: queryParams.get('ppLink') ? queryParams.get('ppLink') : '',
        ppTcLink: queryParams.get('ppTcLink') ? queryParams.get('ppTcLink') : '',
        facebookPixel: queryParams.get('facebookPixel') ? queryParams.get('facebookPixel') : '',
        facebookPixelId: queryParams.get('facebookPixelId') ? queryParams.get('facebookPixelId') : '',
        confirmAndCompleteBooking: queryParams.get('confirmAndCompleteBooking') ? queryParams.get('confirmAndCompleteBooking') : '',
        itSeemsWeWereNotAbleToReceiveYourMoney: queryParams.get('itSeemsWeWereNotAbleToReceiveYourMoney') ? queryParams.get('itSeemsWeWereNotAbleToReceiveYourMoney') : '',
        pleaseLetUsKnowIfYouHave: queryParams.get('pleaseLetUsKnowIfYouHave') ? queryParams.get('pleaseLetUsKnowIfYouHave') : '',
        aRequestWasSuccessfullySentToVenue: queryParams.get('aRequestWasSuccessfullySentToVenue') ? queryParams.get('aRequestWasSuccessfullySentToVenue') : '',
        pleaseConfirmYourBelowDetailsSoWeCanContactYouRegardingYourBooking: queryParams.get('pleaseConfirmYourBelowDetailsSoWeCanContactYouRegardingYourBooking') ? queryParams.get('pleaseConfirmYourBelowDetailsSoWeCanContactYouRegardingYourBooking') : '',
        previewPage: queryParams.get('previewPage') ? queryParams.get('previewPage') : 0,
        freeText: queryParams.get('freeText') ? queryParams.get('freeText') : '',
        widgetAddReservationClosedFutureDays: queryParams.get('widgetAddReservationClosedFutureDays') ? queryParams.get('widgetAddReservationClosedFutureDays') : '',
        widgetAddReservationSuccess: queryParams.get('widgetAddReservationSuccess') ? queryParams.get('widgetAddReservationSuccess') : '',
        widgetAddReservationFailure: queryParams.get('widgetAddReservationFailure') ? queryParams.get('widgetAddReservationFailure') : '',
        widgetAddReservationAboveLimit: queryParams.get('widgetAddReservationAboveLimit') ? queryParams.get('widgetAddReservationAboveLimit') : '',
        venueWebsiteUrl: queryParams.get('venueWebsiteUrl') ? queryParams.get('venueWebsiteUrl') : '',
        venueGoogleMapUrl: queryParams.get('venueGoogleMapUrl') ? queryParams.get('venueGoogleMapUrl') : '',
        joinTheWaitlist: queryParams.get('joinTheWaitlist') ? queryParams.get('joinTheWaitlist') : '',
        allowGuestToJoinWaitlistAnyTime: queryParams.get('allowGuestToJoinWaitlistAnyTime') ? queryParams.get('allowGuestToJoinWaitlistAnyTime') : '',
        timeFormat: ''
    },
    allowedDays: [],
    allAllowedDays: [],
    venuesList: [],
    multipleVenues: 0,
    sections: [],
    accessToken: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null,
    loaderToggle: false,
    timerToggle: false,
    previewMode: queryParams.get('preview') ? true : false,
    countries: [],
    defaultLang: 'en',
    events: [],
    widgetLanguageOptions: queryParams.get('widgetLanguageOptions') ? queryParams.get('widgetLanguageOptions') : [{ 'name': 'English', 'code': 'en', 'is_default': true, 'is_selected': true }, { 'name': 'Russian', 'code': 'ru', 'is_default': false, 'is_selected': true }, { 'name':'Arabic', 'code': 'ar', 'is_default': false, 'is_selected': true }],
    // previewLineLinks: ["/", "/login", "/success", "/failed"],
    previewLineLinks: ["/", "/login", "/success", "/success"],
    gtm_initialized: false,
    font_initialized: false,
    allShiftsAvailableTimes: [],
    shiftsAvailableTimes:[],
    selectedEvent: {},
    eventDetails: {},
    selectedPaymentGroup: {},
    settingsLoaded:false,
    cancelReservationOptions: queryParams.get('cancelReservationReason') ? queryParams.get('cancelReservationReason') : [
        {
            "id": 1,
            "name": "Change of plan",
            "slug": "change_of_plan",
            "value": "Change of plan"
        },
        {
            "id": 2,
            "name": "Other venue preferred",
            "slug": "other_venue_preferred",
            "value": "Other venue preferred"
        },
        {
            "id": 3,
            "name": "The group has cancelled the outing",
            "slug": "the_group_has_cancelled_the_outing",
            "value": "The group has cancelled the outing"
        },
        {
            "id": 4,
            "name": "Got stuck on my way",
            "slug": "got_stuck_on_my_way",
            "value": "Got stuck on my way"
        },
        {
            "id": 5,
            "name": "Personal emergency",
            "slug": "personal_emergency",
            "value": "Personal emergency"
        },
        {
            "id": 6,
            "name": "Work reasons",
            "slug": "work_reasons",
            "value": "Work reasons"
        },
        {
            "id": 7,
            "name": "Other reasons",
            "slug": "other_reasons",
            "value": ""
        }
    ],
};

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        incrementLoader: (state) => {
            if (!state.previewMode)
                state.loaderToggle = true;
        },
        decrementLoader: (state) => {
            if (!state.previewMode)
                state.loaderToggle = false;
        },
        updateProperty: (state, data) => {
            state.reservation[data.payload.key] = data.payload.value;
        },
        updateUserData: (state, data) => {
            state.userData[data.payload.key] = data.payload.value;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        incrementByAmount: (state, action) => {
            state.value += action.payload;
        },
        updateStateVariable: (state, data) => {
            state[data.payload.key] = data.payload.value;
        },
        AccessToken: (state, data) => {
            return state.accessToken;
        },
        timerUpdate: (state, data) => {
            state.timerToggle = !state.timerToggle;
        },
        resetReservation: (state, data) => {
            state.reservation = {
                tableFor: 0,
                date: null,
                time: {
                    time: ''
                },
                section: {
                    name: ''
                },
                eventSection: {
                    name: ''
                },
                selectedEvent: {},
                allShiftsDisabled: false,
                newsletter: false,
                selectedCategories: [],
                preferredTable: 'Any section',
                totalAmount: 0,
                section_id: 0,
                reservation_type: 'normal',
                referenceCode: '',
                duration: 0
            }
        },
        themeHandler: (state, data) => {
            let theme = data.payload;
            let finalThemeObject = [];
            let widgetLanguage = theme.filter((value) => {
                return value.slug === 'venue_languages';
            });
            if (widgetLanguage.length) {
                state.widgetLanguageOptions = widgetLanguage[0].value;
                let defaultLang = widgetLanguage[0].value.filter((value) => {
                    return value.is_default === true;
                });
                state.defaultLang = defaultLang.length ? defaultLang[0].code : 'en';
            }

            let cancelReservationReason = theme.filter((value) => {
                return value.slug === 'cancel_reservation_reason';
            });

            if (cancelReservationReason.length) {
                state.cancelReservationOptions = cancelReservationReason[0].option;
            }

            theme.forEach(element => {
                let slug = toCamelCase(element.slug);
                if (element.value !== '' && (state.theme[slug] === '' || state.theme[slug] === undefined)) {
                    finalThemeObject[slug] = element.type === 'integer' ? parseInt(element.value) : element.value;
                }
            });
            let stheme = { ...state.theme, ...finalThemeObject };
            state.theme = stheme;
        },
        updateTags: (state, data) => {
            state.tagsIds[data.payload.key] = data.payload.value;
        },
        updateSelectedTags: (state, data) => {
            state.selectedTags[data.payload.key] = data.payload.value;
        },
        updateTagNote: (state, data) => {
            state.reservation[data.payload.key] = state.reservation[data.payload.key] ? state.reservation[data.payload.key] + ', ' + data.payload.value : data.payload.value;
        },
        updateNote: (state, data) => {
            state.reservation[data.payload.key] = data.payload.value;
        },
        resetSelectedTags: (state, data) => {
            state.tagsIds[data.payload] = [];
            state.selectedTags[data.payload] = [];
        },
    },
});

export const {
    incrementLoader,
    decrementLoader,
    incrementByAmount,
    updateProperty,
    setTheme,
    updateUserData,
    updateStateVariable,
    AccessToken,
    themeHandler,
    timerUpdate,
    onPreviewPageChange,
    resetReservation,
    defaultLang,
    updateTags,
    updateSelectedTags,
    updateTagNote,
    updateNote,
    resetSelectedTags
} = storeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.counter.value;
export const reservation = (state) => state.counter.reservation;
export const userData = (state) => state.counter.userData;
export const orders = (state) => state.counter.orders;
export const currency = (state) => state.counter.currency;
export const timer = (state) => state.counter.timer;
export const theme = (state) => state.counter.theme;
export const highlightedEvents = (state) => state.counter.highlightedEvents;
export const allowedDays = (state) => state.counter.allowedDays;
export const allAllowedDays = (state) => state.counter.allAllowedDays;
export const venuesList = (state) => state.counter.venuesList;
export const sections = (state) => state.counter.sections;
export const accessToken = (state) => state.counter.accessToken;
export const widgetTags = (state) => state.counter.widgetTags;
export const allergiesTags = (state) => state.counter.allergiesTags;
export const dietaryTags = (state) => state.counter.dietaryTags;
export const tagsIds = (state) => state.counter.tagsIds;
export const selectedTags = (state) => state.counter.selectedTags;
export const occasionsTags = (state) => state.counter.occasionsTags;
export const timerToggle = (state) => state.counter.timerToggle;
export const multipleVenues = (state) => state.counter.multipleVenues;
export const countries = (state) => state.counter.countries;
export const previewLineLinks = (state) => state.counter.previewLineLinks;
export const events = (state) => state.counter.events;
export const preferredTables = (state) => state.counter.preferredTables;
export const preferredSections = (state) => state.counter.preferredSections;
export const availableTimes = (state) => state.counter.availableTimes;
export const venueDetails = (state) => state.counter.venueDetails;
export const allShiftsAvailableTimes = (state) => state.counter.allShiftsAvailableTimes;
export const shiftsAvailableTimes = (state) => state.counter.shiftsAvailableTimes;
export const selectedEvent = (state) => state.counter.selectedEvent;
export const eventDetails = (state) => state.counter.eventDetails;
export const selectedPaymentGroup = (state) => state.counter.selectedPaymentGroup;
export const settingsLoaded = (state) => state.counter.settingsLoaded;

export default storeSlice.reducer;