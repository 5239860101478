import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState,useEffect } from "react";
import {
    updateStateVariable,
} from "./../../features/store/storeSlice";

import { MenuItem, Select} from "@material-ui/core";
export default function Language(props) {
    const dispatch = useDispatch();
    const {theme,widgetLanguageOptions,defaultLang} = useSelector((state) => state.store);
    const { t,i18n } = useTranslation();
    const history = useHistory();
    const fontDesign = {
      fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    };
    const [selectedLanguage, setSelectedLanguage] = useState("");
    function onChangeLanguage(e){
      let lang=e.target.value;
      setSelectedLanguage(lang);
      dispatch(updateStateVariable({ key: "defaultLang", value: lang }));
      if(lang==='ar'){
          i18n.changeLanguage('ar');
      }
      if(lang==='en'){
          i18n.changeLanguage('en');
      }
      if(lang==='ru'){
          i18n.changeLanguage('ru');
      }
      if(lang==='de'){
          i18n.changeLanguage('de');
      }
      if(lang==='es'){
        i18n.changeLanguage('es');
    }
    if(lang==='pt'){
      i18n.changeLanguage('pt');
  }
    }
    useEffect(() => {
        if(defaultLang){
      if(defaultLang==='ar'){
        i18n.changeLanguage('ar');
    }
    if(defaultLang==='en'){
        i18n.changeLanguage('en');
    }
    if(defaultLang==='ru'){
        i18n.changeLanguage('ru');
    }
    if(defaultLang==='de'){
        i18n.changeLanguage('de');
    }
    if(defaultLang==='es'){
      i18n.changeLanguage('es');
  }
  if(defaultLang==='pt'){
    i18n.changeLanguage('pt');
}
        }
    }, [defaultLang]);
    function setSelected(){
        let result=widgetLanguageOptions.filter((element)=>{
            return element.is_default===true
        });
        if(result.length){
            setSelectedLanguage(result[0].code);
            return result[0].code
        }else{
            return 'en'
        }
    }
    return (
        <FormControl className="selectLanguage selectLanguagePreview">
        <Select
        style={fontDesign}
        //   native
          value={defaultLang}
          onChange={onChangeLanguage}
          inputProps={{
            name: 'language',
          }}
        >
          {widgetLanguageOptions.length > 0 ? widgetLanguageOptions.map((element,index) => (
          element.is_selected?<MenuItem key={`venues-${index}`} value={element.code}>{element.name}</MenuItem>:''
          )):''}
        </Select>
        {/* <Select
            //   IconComponent={props => (
            //     <i {...props}>
            //       <ArrowDropDownIcon style={{ color: dropDownDesign.color }} />
            //     </i>
            //   )}
              value={defaultLang}
              onChange={onChangeLanguage}
              style={fontDesign}
              variant="outlined"
              className="languageDropdown"
            >
              {widgetLanguageOptions.map((element, key) => (
                <MenuItem key={`venues-${key}`} value={element.code}>{element.name}</MenuItem>
              ))}
              
            </Select> */}
      </FormControl>
    );
}