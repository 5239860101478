import * as React from 'react';
import Loaders from "react-loader-spinner";
import Backdrop from '@material-ui/core/Backdrop';
import { useSelector } from "react-redux";

export default function Loader(props) {
  const loaderToggle = useSelector((state) => state.store.loaderToggle);
    return (<Backdrop
        className="overlay"
        open={loaderToggle}
      >
        <Loaders
        className="loader"
        type="Circles"
        color="#00A0E1"
        height={75}
        width={75}
        visible={loaderToggle}
      />
      </Backdrop>
    );
}
        