import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Divider, Typography } from '@material-ui/core';
import { ReactComponent as WarningIcon } from '../../assets/svg/warning.svg';
import LayoutItem from "../layout/LayoutItem";
import '../../assets/scss/CancellationFailed.scss';

function CancellationFailed({ errors }) {
    const { t } = useTranslation();
    const { theme } = useSelector((state) => state.store);

    const style = {
        color: theme.calendarTextColor ? theme.calendarTextColor : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ""
    };

    return (
        <React.Fragment>
            <Box component={'div'} className='cancellationFailedContainer'>
                <WarningIcon />
                <Typography variant="h3" style={style}>{errors ? t(errors) : t('something_went_wrong')}</Typography>
                {!errors && <React.Fragment>
                    <Typography variant="p" style={style}>{t('your_reservation_was_not_cancelled')}</Typography>
                    <Typography variant="p" style={style}>{t('try_again_later_or_contact_the_venue')}</Typography>
                </React.Fragment>}
            </Box>
            <LayoutItem xs={12}>
                <Divider />
            </LayoutItem>
        </React.Fragment>
    );
}

export default CancellationFailed;