import * as React from 'react';
import Loaders from "react-loader-spinner";
import Backdrop from '@material-ui/core/Backdrop';
import { useSelector } from "react-redux";

export default function SmallLoader({toggle=false}) {
  let togg=toggle;
    return (
        <Loaders
        className="loader smallLoader"
        type="Circles"
        color="#00A0E1"
        height={75}
        width={75}
        visible={togg}
      />
    );
}
        