import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import '../../assets/scss/CancellationReason.scss';

function CancellationReason({ setData, errors }) {
    const { t } = useTranslation();
    const { cancelReservationOptions, theme } = useSelector((state) => state.store);
    const [reason, setReason] = useState('Change of plan');
    const [other, setOther] = useState('');

    const style = {
        color: theme.calendarTextColor ? theme.calendarTextColor : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ""
    };

    const handleCancellationReason = (event) => {
        setOther('');
        setReason(event.target.value);
        setData(event.target.value);
        if (event.target.value === '') {
            setData('');
        }
    };

    const changeTextField = (e) => {
        setOther(e.target.value);
        setData(e.target.value);
    }

    return (
        <Box component={'div'} className='cancellationReasonContainer'>
            <Typography variant="body" style={style}>{t("please_take_a_moment_to_tell_us_why_you_would_like_to_cancel_your_reservation")}</Typography>
            <RadioGroup
                value={reason}
                onChange={handleCancellationReason}
                aria-label="cancellation_reason"
                name="cancellation_reason"
            >
                {cancelReservationOptions.map((option, index) => {
                    return (
                        <FormControlLabel
                            key={`reason-${index}`}
                            className={option.slug === 'other_reasons' ? "otherReason" : ''}
                            value={option.value}
                            control={<Radio />}
                            label={option.slug === 'other_reasons' ? (<InputField
                                name={'other_reasons'}
                                placeholder={t(option.slug)}
                                variant="outlined"
                                disabled={reason === '' ? false : true}
                                value={other}
                                onChange={(e) => changeTextField(e)}
                                error={errors ? true : false}
                                helperText={errors}
                                style={{ ...style, fontSize: 14 }}
                            />) : (<Typography variant={`body1`} style={{ ...style, fontSize: 14 }}>{t(option.slug)}</Typography>)}
                        />
                    );
                })}
            </RadioGroup>
        </Box>
    );
}

export default CancellationReason;

const InputField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#CCCCCC',
            },
            '&:hover fieldset': {
                borderColor: '#32BD58',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#32BD58',
            }
        }
    }
})((props) => <TextField
    variant='outlined'
    fullWidth
    size='large'
    InputProps={{ className: 'formText valueFont', style: props.style }}  {...props}
/>);