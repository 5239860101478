import * as React from 'react';
import { useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import Header from "./Header";
import './../../assets/scss/Success.scss';
import Copyright from './Copyright';

export default function PaymentFailed(props) {
  const { loaderToggle, previewMode, theme,defaultLang } = useSelector((state) => state.store);
  const reservationInfo = JSON.parse(localStorage.getItem('reservation_info'));

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : ""
  };

  const buttonDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const OutlineButtonDesign = {
    backgroundColor: "transparent",
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    borderColor: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : '',
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : '',
    margin: '15px 0px'
  };

  const renderMap = () => {
    if (theme.venueGoogleMapUrl && previewMode === false) {
      return (<iframe width="100%" height="60" src={theme.venueGoogleMapUrl} frameborder="0" style={{ borderRadius: widgetBackgroundColor.borderRadius }}></iframe>);
    } else if (previewMode) {
      return (<iframe width="100%" height="60" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14455.885974746396!2d55.1388336!3d25.0689553!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d4cc83b4dc7%3A0x89540b27c3c0d55b!2sserVme!5e0!3m2!1sen!2sin!4v1679469262989!5m2!1sen!2sin" frameborder="0" style={{ borderRadius: widgetBackgroundColor.borderRadius }}></iframe>);
    } else {
      return '';
    }
  }

  return (
    <LayoutContainer
      alignItems="center"
      justifyContent="center"
      className={'failedPageContainer border-radius-0'}
      style={widgetBackgroundColor}
    >
      <Header title={theme.headerTitle && theme.headerTitleValue  && defaultLang==='en'? theme.headerTitleValue : ''} />
      {!loaderToggle && <LayoutItem xs={10}>
        <div className="middleContainer">
          <Typography
            variant="h5"
            display="block"
            align="center"
            className="successSubTitle failedPageTitle"
            style={colorDesign}
          >
            {props.title}
          </Typography>
          <div className={`actionContainer ${theme.venueGoogleMapUrl || previewMode ? "actionContainerButton" : ''}`}>
            <Button
              variant="contained"
              fullWidth
              onClick={onButtonClicked}
              style={buttonDesign}
            >{props.buttonText}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              href={theme.venueWebsiteUrl}
              target="_blank"
              style={OutlineButtonDesign}
            >{props.buttonText1}
            </Button>
          </div>
          {renderMap()}
        </div>
      </LayoutItem>}
      {!loaderToggle && <LayoutItem xs={12}>
        <Button
          className="buttonFix noFix"
          style={buttonDesign}
          variant="contained"
          endIcon={<ArrowForwardIosIcon style={{ color: buttonDesign.color }} />}
          href={`${process.env.REACT_APP_PAYMENT_URL}payments/${(JSON.parse(reservationInfo.paymentId))}/page`}
        >
          {props.buttonText2}
        </Button>
      </LayoutItem>}
      <LayoutItem xs={10}>
        <Copyright />
      </LayoutItem>
    </LayoutContainer>
  );
}