import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from "@material-ui/core/styles";
import { updateStateVariable } from '../../features/store/storeSlice';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { lineHeight } from '@material-ui/system';

function AvailableEvent({ details, onSelect }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { defaultLang, selectedEvent, eventDetails, theme } = useSelector((state) => state.store);
  let query = new URLSearchParams(window.location.search);
  const oid = query.get("oid");

  const selectedStyle = {
    color: theme.calendarLinkColor ? theme.calendarLinkColor : '',
    backgroundColor: theme.calendarBoxColor ? theme.calendarBoxColor : '',
    opacity: theme.selectedColorOpacity ? theme.selectedColorOpacity : '',
    border:theme.calendarLinkColor ? '1px solid '+theme.calendarLinkColor : '',
  };
  const defaultBoxColor = {
  //  backgroundColor: theme.calendarBoxColor ? theme.calendarBoxColor : '',
  backgroundColor: '',
    opacity: theme.defaultBoxOpacity ? theme.defaultBoxOpacity : '',
   //color: theme.calendarLinkColor ? theme.calendarLinkColor : '',
   color:  '',
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : '',
  };
  function selectEvent() {
    if (selectedEvent.id !== details.id) {
      dispatch(
        updateStateVariable({
          key: "selectedEvent",
          value: details
        })
      );
      // history.push("/event?oid=237");
      if (onSelect)
        onSelect(details)
    }
  }
  function setEventDetails() {
    dispatch(
      updateStateVariable({
        key: "eventDetails",
        value: details
      })
    );
    history.push("/event?oid=" + oid);
  }

  return (
    <div className={(selectedEvent.id && selectedEvent.id == details.id) ? classes.selectedEventContainer : classes.eventContainer} style={(selectedEvent.id && selectedEvent.id == details.id) ? selectedStyle : defaultBoxColor}>
      <div className={classes.eventInfoContainer}>
        <span className={`${classes.eventInfo} eventName ${defaultLang === 'ar' ? 'eventNameAr' : ''}`} style={details.id == 'shift' ? { width: '100%' } : {}} onClick={() => selectEvent()}>
          {details.event_name}
       
        </span>
        {details.id !== 'shift' && <span className={'eventIconContainer'} onClick={() => setEventDetails()}>
          <div className={classes.eventLine} style={selectedEvent.id && selectedEvent.id == details.id ? { borderColor: selectedStyle.color + '66', borderLeft: '1px solid '+ selectedStyle.color} : { borderColor: defaultBoxColor.color}}></div>
          <div className={classes.eventIcon}>
            <InfoOutlinedIcon />
          </div>
        </span>}
      </div>

    </div>
  )
}

export default AvailableEvent

const useStyles = makeStyles((theme) => ({
  eventContainer: {
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#2A3F54',
    display: 'flex',
    minHeight: 50,
    height: '100%',
    alignItems: 'center',
    // justifyContent: 'center',
    position: 'relative',
    border: '1px solid #EBEEF4',
    margin: '0px 45px',
    "@media (max-width: 767px)": {
      margin: '0px 8%',
    }
  },
  selectedEventContainer: {
    backgroundColor: 'green',
    borderRadius: 5,
    color: '#fff',
    display: 'flex',
    minHeight: 50,
    height: '100%',
    alignItems: 'center',
    // justifyContent: 'center',
    position: 'relative',
    fontWeight: 'bold',
    margin: '0px 45px',
    "@media (max-width: 767px)": {
      margin: '0px 8%',
    },
    eventInfo: {
      fontWeight: 'bold',
    }
  },
  eventInfo: {
    fontSize: 13,
    textAlign: 'center',
    maxWidth: '100%',
    width: '100%',
    display: 'block',
    height: '50px',
    lineHeight: '50px',
    float: 'left',
    textTransform: 'uppercase',
    "@media (max-width: 900px)": {
      width: 'calc(100% - 50px)'
    }
  },

  eventInfoContainer: {
    width: '100%',
    height: '50px',
    lineHeight: '50px',
    minWidth: '100%',
    "& .eventName": {
      "@media (max-width: 767px)": {
        lineHeight: 'normal',
        paddingTop: '7px',
        height: '100%',
        padding: '0 10px !important',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }
    },
    "& .eventNameAr": {
      float: 'right !important',
    }
  },
  eventIconContainer: {
    display: 'flex',
    alignItems: 'center',
    float: 'left',
    cursor: 'pointer',
    position: 'absolute',
    right: 5
  },
  eventLine: {
    minHeight: '50px',
    borderLeft: '1px solid #EBEEF4'

  },
  eventIcon: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '10px',
    paddingLeft: '10px'
  },
}));