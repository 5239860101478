import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: 'en',
    resources: {
        en: {
            translations: require('./en/Translations.json')
        },
        ar: {
            translations: require('./ar/Translations.json')
        },
        ru: {
            translations: require('./ru/Translations.json')
        },
        de: {
            translations: require('./de/Translations.json')
        },
        es: {
            translations: require('./es/Translations.json')
        },
        pt: {
            translations: require('./pt/Translations.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
});


export default i18n;