import * as React from "react";
import "./../assets/scss/PreviewLine.scss";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { updateProperty } from "./../features/store/storeSlice";
import { useHistory } from "react-router-dom";
export default function PreviewLine(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { previewLineLinks, theme } = useSelector((state) => state.store);
  const [currentPage, setCurrentPage] = useState(theme.previewPage ? theme.previewPage - 1 : 0);
  function onPreviewChange(direction) {
    if (direction === "left" && currentPage) {
      setCurrentPage(currentPage - 1);
      history.push({
        pathname: previewLineLinks[currentPage - 1],
        search: window.location.search
      });
    } else if (direction === 'right' && currentPage !== previewLineLinks.length - 1) {
      setCurrentPage(currentPage + 1);
      history.push({
        pathname: previewLineLinks[currentPage + 1],
        search: window.location.search
      });
    }
  }

  const previewMode = (cp) => {
    if (cp === 4) {
      dispatch(updateProperty({ key: "reservation_type", value: 'join_waitlist' }));
    } else {
      dispatch(updateProperty({ key: "reservation_type", value: 'normal' }));
    }
    switch (cp) {
      case 1: return 'Widget landing page'
      case 2: return 'Guest details page'
      case 3: return 'Successful reservation page'
      case 4: return 'Successful waitlist reservation page'

      default:
        break;
    }
  }
  return (
    <div className="previewLineContainer">
      <div className="previewLine">
        <ChevronLeftIcon className="fixedPositionLeft" color={!currentPage ? "disabled" : ''} style={{ cursor: currentPage && 'pointer' }} onClick={() => onPreviewChange("left")} />
        <div className="previewLineText">
          <div className="previewLineLabel">Preview Mode:</div>
          Screen {currentPage + 1} - {previewMode(currentPage + 1)}
        </div>
        <ChevronRightIcon className="fixedPositionRight" color={currentPage === previewLineLinks.length - 1 ? "disabled" : ''} style={{ cursor: currentPage === previewLineLinks.length - 1 || 'pointer' }} onClick={() => onPreviewChange("right")} />
      </div>
    </div>
  );
}
