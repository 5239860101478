import * as React from "react";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import './../../assets/scss/Logo.scss';
import { useSelector } from "react-redux";
import Language from "./Language";

export default function Logo(props) {
  const { theme, widgetLanguageOptions } = useSelector((state) => state.store);

  const backgroundStyle = {
    backgroundImage: theme.coverImageUrl && parseInt(theme.coverImage) ? 'URL(' + theme.coverImageUrl + ')' : '',
    height: theme.coverImageUrl && parseInt(theme.coverImage) ? '200px' : '',
    backgroundSize: theme.coverImageUrl && parseInt(theme.coverImage) ? 'cover' : '',
    backgroundColor: !theme.coverImageUrl && parseInt(theme.coverImage) ? '#C8C8C8' : ''
  };

  function logoIfExist() {
    if (parseInt(theme.logo) && theme.logoUrl) {
      return (<div className="logo"><img src={theme.logoUrl} /></div>)
    }
    return '';
  }

  function logoOrBackground() {
    if ((parseInt(theme.coverImage)) || (parseInt(theme.logo) && theme.logoUrl)) {
      return (
        <LayoutContainer
          alignItems="center"
          justifyContent="center"
          className="logoContainer"
          style={backgroundStyle}
        >
          <LayoutItem xs={12}>
            {logoIfExist()}
          </LayoutItem>
        </LayoutContainer>
      )
    } else {
      return ('')
    }
  }

  return (
    <LayoutContainer>
      <LayoutItem xs={12}>
        <div className={widgetLanguageOptions.filter(language => language.is_selected).length > 0 ? "headerBlock" : "headerBlockEmpty"}>
          {widgetLanguageOptions.filter(language => language.is_selected).length > 0 && <Language />}
        </div>
      </LayoutItem>
      <LayoutItem xs={12}>
        {logoOrBackground()}
      </LayoutItem>
    </LayoutContainer>
  );
}
