import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import moment from 'moment/moment';
import EventHeader from '../shared/EventHeader';
import Create from './Create';
import Back from '../shared/Back';
import Copyright from "../shared/Copyright";
import Layer2 from '../../assets/svg/Layer-2.svg';
import './../../assets/scss/Reservation.scss';

function ViewEventDetails(props) {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const { currency, eventDetails, theme, venueDetails,defaultLang } = useSelector((state) => state.store);
  const [showData, setShowData] = useState(false);
  const [createEventMode, setCreateEventMode] = useState(false);

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : '',
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : '',
  };

  useEffect(() => {
    let path = location.pathname.split("/event/");
    if (path.length > 1) {
      setCreateEventMode(true)
    }
    setShowData(true);
  }, []);

  function eventDateCalculation(startDate, endDate, timeStart, timeEnd) {
    let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(startDate));
    let startDateTime = moment(startDate + 'T' + timeStart).add(-offset, 'minutes').format('dddd DD, MMMM YYYY');
    let endDateTime = moment(endDate + 'T' + timeEnd).add(-offset, 'minutes').format('dddd DD, MMMM YYYY');
    let date = startDateTime;
    if (startDate !== endDate && endDate) {
      date = startDateTime + ' till ' + endDateTime;
    }
    let startTime = theme.timeFormat == '12' ? moment(startDate + 'T' + timeStart).add(-offset, 'minutes').format('hh:mm A') : moment(startDate + 'T' + timeStart).add(-offset, 'minutes').format('HH:mm');
    let endTime = theme.timeFormat == '12' ? moment(endDate ? endDate + 'T' + timeEnd : startDate + 'T' + timeEnd).add(-offset, 'minutes').format('hh:mm A') : moment(endDate ? endDate + 'T' + timeEnd : startDate + 'T' + timeEnd).add(-offset, 'minutes').format('HH:mm');
    let time = 'From ' + startTime + ' till ' + endTime
    return <div> {time}</div>
  }

  function number_format(number, decimals = 0, decPoint = '.', thousandsSep = '') {
    const multiplier = Math.pow(10, decimals);
    const roundedNumber = Math.round(number * multiplier) / multiplier;
    const parts = roundedNumber.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);
    return parts.join(decPoint);
  }


  const displayPrice = (amount, isVatIncluded) => {
    let vatPercentage = venueDetails.vat_percentage;
    if (!isVatIncluded && vatPercentage && vatPercentage !== '' && vatPercentage != 0) {
      amount = Number(amount);
      let tempAmount = 0;
      tempAmount = amount * (vatPercentage / 100);
      amount = amount + tempAmount;
      amount = number_format(amount, 2);
    }
    return amount;
  }
 

  const checkDuration = (eventDetails,pack)=>{
  if(eventDetails.settings.show_duration_for_packages == 1){
    return (
      <>
      <div className={classes.eventPackage}>
      <div className="packageHeader">
        <span className='header'>{pack.package_name}</span>
        <span className='description'>
          {pack.package_description}
        </span>
      </div>
      <div className='packagePrice'>
        <p className='price'>{currency} {displayPrice(pack.package_amount, eventDetails.payment_group ? eventDetails.payment_group.is_vat_included : false)}</p>
        <span className='priceDestribution'>/{pack.booking_per}</span>
      </div>
    </div>
    </>
    )
  }
  else if(eventDetails.settings.show_duration_for_packages == 0 && pack.parent_id == 0){
    return (
      <div className={classes.eventPackage}>
      <div className="packageHeader">
        <span className='header'>{pack.package_name}</span>
        <span className='description'>
          {pack.package_description}
        </span>
      </div>
      <div className='packagePrice'>
        <p className='price'>{currency} {displayPrice(pack.package_amount, eventDetails.payment_group ? eventDetails.payment_group.is_vat_included : false)}</p>
        <span className='priceDestribution'>/{pack.booking_per}</span>
      </div>
    </div>
    )

  }

  }

  return (
    showData && <Grid container style={widgetBackgroundColor} alignItems="center" justifyContent="center" className={!createEventMode ? "eventShowDetails viewEvent" : 'viewEvent'}>
      <Grid item xs={12}>
        <EventHeader details={eventDetails} />
      </Grid>
      <Grid container spacing={2} alignItems='center' justifyContent='space-between' className={`eventPadding ${classes.container}`}>
        <Grid item xs={12}>

          <Box component={`div`} className={`infoContainer ${classes.backBtnEvent}`}>
            <Back link={{ link: "/", 'text': t('back_to_widget') }} />
          </Box>

          {eventDetails.payment_group && eventDetails.payment_group.is_active && eventDetails.payment_group.is_online_active && eventDetails.payment_group.payment_type === 'direct' ? <Grid xs={12}>
            <Chip style={{ backgroundColor: "#D8EFDC", color: '#60BA64' }} size="small" label={t('direct_deposit')} avatar={<img src={Layer2} />} />
          </Grid> : ''}

          <Grid container className='eventDetailsHeader' spacing={2}>
            <Grid className="eventName" item xs={12}>
              {eventDetails.event_name}
            </Grid>
            <Grid className="eventDate" item xs={12}>
              {eventDateCalculation(eventDetails.date_from, eventDetails.date_to ? eventDetails.date_to : '', eventDetails.time_start, eventDetails.time_end)}
            </Grid>
          </Grid>

          <p className={classes.heading}>{t('about_the_event')}</p>
          <span className={classes.fontStyle}>
            <div className={classes.eventDescription} dangerouslySetInnerHTML={{ __html: eventDetails.description }}></div>
          </span>
        </Grid>
        {eventDetails.payment_group && !eventDetails.payment_group.hide_online_packages_categories && eventDetails.payment_group.packages && !createEventMode ?
          <Grid item xs={12}>
            <p className={classes.heading}>Packages</p>
            {eventDetails.payment_group.packages.map(pack =>
            {
            return (
              <>
            {checkDuration(eventDetails,pack)}
            </>
            )}
              )}
          </Grid> : ''}
        {!createEventMode ? <>
        {/* <Grid item xs={12}>
          <p className={classes.heading}>Reservation policy</p>
          <span className={classes.fontStyle}>
            Bar Entrance - Ladies & Couples - No minimum spend Gents - AED400 per person minimum spend Table reservations starting from AED4000 Walk-ins are welcome; Complimentary beverages at the bar for ladies on Wed from 11pm till 1am and Thu from 10pm till midnight. Strict door policy applies.
          </span>
        </Grid> */}
          {eventDetails.payment_group && <Grid item xs={12}>
            <p className={classes.heading}>Payment policy</p>
            <span className={classes.fontStyle}>
              <div className={classes.eventDescription} dangerouslySetInnerHTML={{ __html: eventDetails.payment_group.payment_policy }}></div>
            </span>
          </Grid>}
          <Grid item xs={1} />
          {/* <Grid item xs={10}>
          <AvailableEvent />
        </Grid> */}
          <Grid item xs={1} />
          {/* <Grid style={{ height: 80 }} item xs={10}>
          <AvailableEvent />
        </Grid> */}
          {/* <Grid item xs={2} style={{ height: 80 }}>
          <NumberIncrementor />
        </Grid> */}
        </> :
          <>
            <Grid item xs={12} style={widgetBackgroundColor} className="createReservationForm">
              {!props.errorMessage && <Create getDataFunction={props.getDataFunc} mode="event" />}
              {props.errorMessage && <><Grid item
                xs={12}
                className="closedVenueError venueCloseContainer"
                style={colorDesign}
              >
                {props.errorMessage === 'widgetAddReservationFailure' ? theme.widgetAddReservationFailure  && defaultLang==='en'? theme.widgetAddReservationFailure : t('dear_guest_we_are_not_accepting_online_reservations_at_the_moment_please_call_us_to_make_a_reservation') : props.errorMessage}
              </Grid>
                <Grid item xs={12} className="padding-b-0">
                  <Copyright />
                </Grid>
              </>
              }
            </Grid></>}
      </Grid>
    </Grid>
  );
}

export default ViewEventDetails;


const useStyles = makeStyles({
  container: {
    textAlign: 'start',
    width: '100%',
    // marginRight: 0,
    // marginLeft: 0,
    //paddingTop: '25px !important',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  heading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    marginTop: 5,
    color: '#2A3F54'
  },
  fontStyle: {
    fontSize: 14,
    // lineHeight: '16pt',
    color: '#2A3F54',
    // marginBottom: 20,
  },
  eventPackage: {
    display: 'flex', justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EBEEF4',
    padding: 10,
    marginBottom: 10,
    marginTop: 8,

    '& .packageHeader': {
      display: 'flex',
      flexDirection: 'column',
      '& .header': {
        color: '#2A3F54',
        fontSize: '14px',
        fontWeight: 'bold',
        opacity: 0.7
      },
      '& .description': {
        color: '#2A3F54',
        fontSize: '13px',
        opacity: 1
      }
    },
    '& .packagePrice': {
      display: 'flex',
      flexDirection: 'column',
      opacity: 1,
      textAlign: 'right',

      '& .price': {
        color: '#8F5D35',
        fontSize: '14px',
        fontWeight: 'bold',
        minWidth: 50,
        margin: 0
      },
      '& .priceDestribution': {
        color: '#2A3F54',
        fontSize: '10px',
        height: 12,
      }
    }
  },
  eventDescription: {
    '& p': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  backBtnEvent: {
    marginBottom: '20px',
    '& .backButton': {
      display: 'table',
    }
  }
});
