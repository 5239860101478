import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Button, Divider, Typography } from "@material-ui/core";
import { resetReservation } from "./../../features/store/storeSlice";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import Header from "./Header";
import ReservationDetail from "../shared/ReservationDetail";
import Packages from "../event/Packages";
import './../../assets/scss/Success.scss';
import Copyright from './Copyright';

export default function Success(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { defaultLang, previewMode, reservation, theme, userData, venueDetails } = useSelector((state) => state.store);
  let reservationInfo = JSON.parse(localStorage.getItem('reservation_info'));

  if (previewMode) {
    reservationInfo = {
      date: reservation.date,
      time: reservation.time.time ? reservation.time.time : reservation.time.start_time,
      tableFor: reservation.tableFor,
      firstName: userData.firstName,
      lastName: userData.lastName,
      selectedCategories: reservation.selectedCategories,
      total: reservation.totalAmount,
      type: reservation.reservation_type,
      language: defaultLang,
      allergies: reservation.allergies,
      dietary: reservation.dietary,
      occasion: reservation.occasion,
      widget: reservation.widget,
      reservation_type: reservation.reservation_type,
      referenceCode: reservation.referenceCode,
      payment_settings: reservation.section.payment_settings
    }
  }

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const buttonDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const OutlineButtonDesign = {
    backgroundColor: "transparent",
    color: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : "",
    borderColor: theme.buttonBackgroundColor ? theme.buttonBackgroundColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : '',
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : '',
    margin: '15px 0px'
  };

  function onButtonClicked() {
    let query = new URLSearchParams(history.location.search);
    let oid = query.get("oid");
    dispatch(resetReservation());
    history.push({
      pathname: '/',
      search: window.location.search,
    })
    history.go(0);
  }

  function timeFormater(time) {
    let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date));
    let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
    // if (is_dst) {
    //   offset += 60;
    // }
    return moment(time, 'HH:mm').add(-offset, 'minutes').format('HH:mm')
  }

  const renderMap = () => {
    if (theme.venueGoogleMapUrl && previewMode === false) {
      return (<iframe width="100%" height="60" src={theme.venueGoogleMapUrl} frameborder="0" style={{ borderRadius: widgetBackgroundColor.borderRadius }}></iframe>);
    } else if (previewMode) {
      return (<iframe width="100%" height="60" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14455.885974746396!2d55.1388336!3d25.0689553!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d4cc83b4dc7%3A0x89540b27c3c0d55b!2sserVme!5e0!3m2!1sen!2sin!4v1679469262989!5m2!1sen!2sin" frameborder="0" style={{ borderRadius: widgetBackgroundColor.borderRadius }}></iframe>);
    } else {
      return '';
    }
  }

  return (
    <LayoutContainer
      alignItems="center"
      justifyContent="center"
      className="successPageContainer"
      style={widgetBackgroundColor}
    >
      <Header title={theme.headerTitle && theme.headerTitleValue && defaultLang==='en' ? theme.headerTitleValue : ''} />
      <LayoutItem xs={10}>
        <div className="middleContainer">
          <Typography
            variant="h5"
            display="block"
            align="center"
            className="successSubTitle"
            style={colorDesign}
          >
            {props.title}
          </Typography>
          <div className={`actionContainer ${theme.venueGoogleMapUrl || previewMode ? "actionContainerButton" : ''}`}>
            <Button
              variant="contained"
              fullWidth
              onClick={onButtonClicked}
              style={buttonDesign}
            >{props.buttonText}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              href={theme.venueWebsiteUrl}
              target="_blank"
              style={OutlineButtonDesign}
            >{props.buttonText2}
            </Button>
          </div>
        </div>
        {renderMap()}
      </LayoutItem>
      <LayoutItem xs={10}>
        <Divider />
        <ReservationDetail
          name={reservationInfo.firstName + ' ' + reservationInfo.lastName}
          description={reservationInfo.reservation_type === 'join_waitlist' ? t("waitlist_booking_details") : t("booking_details")}
          date_time={reservationInfo.date}
          persons={reservationInfo.tableFor}
          referenceCode={reservationInfo.referenceCode}
          total={reservationInfo.total}
          type={reservationInfo.reservation_type}
          allergies={reservationInfo.allergies}
          dietary={reservationInfo.dietary}
          occasion={reservationInfo.occasion}
          widget={reservationInfo.widget}
          paymentSettings={reservationInfo.payment_settings}
        />
        <Packages
          list={reservationInfo.selectedCategories}
          vatValue={venueDetails.vat_percentage}
          isVatIncluded={reservationInfo?.payment_settings?.is_vat_included}
          type=''
          onChoosePackage={() => { }}
          onChooseNumber={() => { }}
        />
      </LayoutItem>
      <LayoutItem xs={10}>
        <Copyright />
      </LayoutItem>
    </LayoutContainer>
  );
}