import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import Create from "./Create";
import Header from '../shared/Header';
import Copyright from "../shared/Copyright";
import './../../assets/scss/Reservation.scss';

export default function MakeReservation(props) {
  const { theme } = useSelector((state) => state.store);

  const { t } = useTranslation();
  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };
  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : '',
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : '',
  };
  return (
    <LayoutContainer className="makeReservation hhh"
      style={widgetBackgroundColor}>
      <Header getDataFunction={props.getDataFunc} errorMessage={props.errorMessage} title={theme.headerTitle && theme.headerTitleValue ? theme.headerTitleValue : ''} />
      <LayoutItem xs={12} style={widgetBackgroundColor} className="createReservationForm">
        {!props.errorMessage && <Create getDataFunction={props.getDataFunc} mode='normal'/>}
        {props.errorMessage && <><LayoutItem
          xs={12}
          className="closedVenueError venueCloseContainer"
          style={colorDesign}
        >
          {props.errorMessage === 'widgetAddReservationFailure' ? theme.widgetAddReservationFailure ? theme.widgetAddReservationFailure : t('dear_guest_we_are_not_accepting_online_reservations_at_the_moment_please_call_us_to_make_a_reservation') : props.errorMessage}
        </LayoutItem>
          <LayoutItem xs={12} className="padding-b-0">
            <Copyright />
          </LayoutItem>
        </>
        }
      </LayoutItem>
    </LayoutContainer>
  );
}
